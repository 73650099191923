import { Grid } from "@mui/material"
import styles from '../ui.module.scss';

export const ButtonLayout = ({ fastGrid, secendGrid }: any) => {

    return (
        <>
            <Grid container className={styles.gridcontainer}>
                <Grid item md={6}>
                    {fastGrid}
                </Grid>
                <Grid item md={6} sx={{display:"flex",justifyContent:"end"}}>
                    {secendGrid}
                </Grid>
            </Grid>
        </>
    )
}