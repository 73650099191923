import { Box } from "@mui/material"


export const InputLayout = ({ children,style }: any) => {

    return (
        <Box sx={{ margin: '16px 0',...style }}>
            {children}
        </Box>
    )
}