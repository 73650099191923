import { Button } from "react-bootstrap";
import Request_PaymentTebal from "./components/Request_Payment/Request_PaymentTebal";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../../_helpers";
import styles from './index.module.scss';

const Request_Payment_main = () => {
    const navigate = useNavigate();
    const user = getUser()?.data ?? null;
    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });
    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="balance__header product_btn_wrapper">
                    <div className="balance__header__name">Request Payment</div>
                    {user.role_name == 'Associate' || user.role_name == "Auditor" ? "" :

                        <div>
                            <>
                                <Button
                                    className={`balance__save-btn ${styles.product_btn_wrapper} custom_mobile_btn`}
                                    onClick={() => {
                                        navigate("/Create-RequestPayment")
                                    }}
                                >
                                    Create New
                                </Button>
                            </>
                        </div>}
                </div>
                <Request_PaymentTebal />
            </ThemeProvider>
        </>
    )
}
export default Request_Payment_main;