import { Box, Grid, Typography } from '@mui/material';
import styles from './connectInvoiceTable.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { isUserAuthenticated, authHeader } from '../../../../../../../../_helpers';
import axios from 'axios';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from "react-redux";
import { counterpartsActions } from '../../../../../../../../_actions/counterparts.actions'
import { TebalNoData } from '../../../../../Skeleton/TebalNoData';
import { ListSkeleton } from '../../../../../Skeleton/Skeleton';
import { useNavigate } from "react-router-dom";
import { dashboardActions } from '../../../../../../../../_actions';
import { selectAllData } from "../../../../../../../../_reducers/dashboard.reducer";

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}
interface Props {
    invoiceDetail: any;
    setPageNo: any;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'Date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    {
        id: 'Payment Reference',
        numeric: true,
        disablePadding: false,
        label: 'Payment Reference',
    },
    {
        id: 'Category',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'Description',
        numeric: true,
        disablePadding: false,
        label: 'Description',
    },

    {
        id: 'Transaction',
        numeric: true,
        disablePadding: false,
        label: 'Transaction',
    },
    {
        id: 'Reconciled Amount',
        numeric: true,
        disablePadding: false,
        label: 'Reconciled Amount',
    },
];

const ConnectInvoiceTable = ({ invoiceDetail, setPageNo, setSelected, selected }: any) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [search, setSearch] = React.useState('');
    const [categ, setCateg] = React.useState('');
    const [loderinapi, setLoderapi] = React.useState(false);
    const [datatabal, setDatatebal] = React.useState<any>([]);
    const [selectData, setSelecetData] = useState([])
    const [totalCount, setTotalCount] = React.useState(10);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [pageneshn, setPagelist] = React.useState(1);
    const [remove, setRemovedata] = React.useState(10);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(0);
    const [Redusar, setRedusar] = useState(true)
    const [loader, setLoader] = useState(false)
    const [ListdoupDoun, setListdoupDoun] = useState([])
    const [listRender, setListRender] = useState(false)
    const [customer_name, setCustomer_name] = useState("")
    const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);
    const moniteInvoiceId = invoiceDetail?.monite_invoice_id
    const invoiceBalance = !!invoiceDetail?.due_amount ? invoiceDetail?.due_amount : 0
    const invoiceNo = !!invoiceDetail?.invoice_no ? invoiceDetail?.invoice_no : '-'

    const [remainingBalance, setRemainingBalance] = useState();
    const data = useSelector((state) => selectAllData(state));

    console.log(selected, 'selectedselected');
    useEffect(() => {
        setRemainingBalance(!!invoiceDetail?.due_amount ? invoiceDetail?.due_amount : 0)
    }, [invoiceDetail])
    console.log(remainingBalance, "remainingBalance");

    const getBalanceList = async (search: any, status: any, customer_name: any, stateDate: any, endDate: any, pageneshn: any) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
        };
        const stateDateApi = !!stateDate
            ? moment(stateDate).format('YYYY-MM-DD')
            : '';
        const endDateApi = !!endDate
            ? moment(endDate).format('YYYY-MM-DD')
            : '';
        const category = !!categ ? categ : '';

        var pagelimit = 10;
        setLoderapi(true);
        const response = await axios
            .get(
                `${process.env.REACT_APP_API_URL}/invoice/get-transactions/${invoiceDetail.currency}?search=${search}&payment_channel=${customer_name}&page=${pageneshn}&limit=${pagelimit}`,
                requestOptions
            )
            .then((response) => {
                if (response.data.success == true) {
                    setDatatebal(response.data.data.data);
                    setTotalCount(response.data.data.total);
                    const newSelected: any = []

                    for (let index = 0; index < response.data.data.data.length; index++) {
                        const element = response.data.data.data[index];
                        if (element.status == "paid") {

                        } else {
                            newSelected.push(element.id)
                        }
                    }
                    setSelecetData(newSelected)
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 2000);

                } else {
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 6000);
                }

            });
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected: any = []
            for (let index = 0; index < datatabal.length; index++) {
                const element = datatabal[index];
                if (element.status == "paid") {

                } else {
                    newSelected.push(element.id)
                }
            }
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };


    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datatabal.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    function doneTyping() {
        getBalanceList(search, categ, customer_name, startDate, endDate, pageneshn);
        setLoderapi(true);
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            doneTyping();
            console.log('usereffect testing');
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        setTimeout(() => {
            if (isUserAuthenticated() && Redusar && invoiceDetail?.currency) {
                const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                const endDateApi = !!endDate
                    ? moment(endDate).format('YYYY-MM-DD')
                    : '';
                getBalanceList(search, categ, customer_name, stateDateApi, endDateApi, pageneshn)
                dispatch(counterpartsActions.getCustomerDropDoun() as any);
                setTimeout(() => {
                    setListRender(true)
                }, 4000)
            }
        }, 1000);
    }, [Redusar, isUserAuthenticated, invoiceDetail?.currency])


    const handleClick = (name: string, transactionAmount: number) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];
        let newTotalAmount = totalSelectedAmount;
        console.log(transactionAmount, totalSelectedAmount, "transactionAmount");

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
            newTotalAmount += transactionAmount;
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newTotalAmount -= transactionAmount;
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newTotalAmount -= transactionAmount;
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
            newTotalAmount -= transactionAmount;
        }

        setSelected(newSelected);
        setTotalSelectedAmount(newTotalAmount);
        console.log(newTotalAmount, invoiceBalance, "newTotalAmount");

        const newRemainingBalance = invoiceBalance ? invoiceBalance - newTotalAmount : invoiceBalance;
        setRemainingBalance(newRemainingBalance);
    };


    const handleLinkInvoice = (status: any) => {
        if (status === "submit") {
            setLoader(true)
            const body = {
                "monite_invoice_id": moniteInvoiceId,
                "transaction_ids": selected
            }
            dispatch(counterpartsActions.LinkInvoicetoTransaction(body, moniteInvoiceId, setPageNo) as any);

            setTimeout(() => {
                setLoader(false)
            }, 2000);
        } else if (status === "cancel") {
            navigate("/invoice")
        }
    }


    useEffect(() => {
        if (invoiceDetail.currency) {
            dispatch(dashboardActions.getPaymentChannel(invoiceDetail.currency) as any);
        }
    }, [invoiceDetail.currency])


    const memoizedPaymentChannelData = useMemo(() => data?.GET_PAYMENT_CHANNEL_BY_CURRENCY_SUCCESS, [data?.GET_PAYMENT_CHANNEL_BY_CURRENCY_SUCCESS]);
    useEffect(() => {
        if (memoizedPaymentChannelData !== undefined) {
            setListdoupDoun(memoizedPaymentChannelData?.data);
        }
    }, [memoizedPaymentChannelData]);

    // console.log("paymentChannels =>", paymentChannels);


    return (
        <>
            <ThemeProvider theme={theme}>
                <ListSkeleton loader={loderinapi} height={'100vh'} width={'100%'}
                    element={
                        <>
                            <Grid container>
                                <Grid item xs={4}>
                                    <div className={styles.invoiceSummary}>
                                        <label className={styles.headingSummaryTitle}>Total summary of invoice</label>
                                        <div className={styles.summaryRow}>
                                            <div className={styles.summaryLabel}>Invoice No</div>
                                            <div className={styles.summaryValue}>{invoiceNo}</div>
                                        </div>
                                        <div className={styles.summaryRow}>
                                            <div className={styles.summaryLabel}>Invoice amount</div>
                                            <div className={styles.summaryValue}>{invoiceDetail.currency + " " + invoiceBalance}</div>
                                        </div>

                                        <div className={styles.summaryRow}>
                                            <div className={styles.summaryLabel}>Paid amount</div>
                                            <div className={styles.summaryValue}>{invoiceDetail.currency + " " + totalSelectedAmount}</div>
                                        </div>

                                        <div className={styles.summaryRow}>
                                            <div className={styles.summaryLabel}>Remaining amount</div>
                                            <div className={styles.summaryValue}>{invoiceDetail.currency + " " + remainingBalance}</div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>



                            <Grid container spacing={1.9} className='grid_bill_container'>
                                <Grid item md={2.9} xs={12}>
                                    <div
                                        className="conteanaha_data_ketete"
                                    >
                                        <InputGroup className="balance__filter-wrapper input">
                                            <InputGroup.Text id="basic-addon1" className="icon">
                                                <SearchIcon />
                                            </InputGroup.Text>
                                            <Form.Control
                                                className="balance-search-input"
                                                placeholder="Search by amount"
                                                aria-label="Search"
                                                aria-describedby="basic-addon1"
                                                disabled={loderinapi}
                                                value={search}
                                                onChange={(event) => {
                                                    if (event.target.value == '') {
                                                        setSearch(event.target.value);
                                                    } else {
                                                        setSearch(event.target.value);
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                </Grid>

                                <Grid item md={1.9} xs={12}>
                                    <div className=" balance_cat">
                                        <Form.Select
                                            onChange={(e: any) => {
                                                setCustomer_name(e.target.value);
                                                getBalanceList(search, categ, e.target.value, startDate, endDate, pageneshn);
                                            }}
                                            value={customer_name}
                                        >
                                            <option selected value="">
                                                Payment Channel
                                            </option>
                                            {ListdoupDoun?.map(
                                                (arrayElement: any, index: number) => {
                                                    return (
                                                        <option
                                                            value={arrayElement.key}
                                                            key={index + 1}
                                                        >
                                                            {arrayElement.key}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Form.Select>
                                    </div>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Paper sx={{ width: '100%', mb: 2, marginTop: "20px", boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)" }}>
                                        <TableContainer>
                                            <Table
                                                className='contenar_tebal_liner'
                                                aria-labelledby="tableTitle"
                                                size={dense ? 'small' : 'medium'}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                icon={<img src="/Checkbox.svg" />}
                                                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                indeterminate={selected.length > 0 && selected.length < selectData.length}
                                                                checked={selectData.length > 0 && selected.length === selectData.length}
                                                                onChange={handleSelectAllClick}
                                                                inputProps={{
                                                                    'aria-label': 'select all desserts',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        {headCells.map((headCell) => (
                                                            <TableCell>
                                                                {headCell.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {datatabal && datatabal.length > 0 ?
                                                        datatabal.map((data: any, index: any) => {
                                                            const isItemSelected = isSelected(data.id);
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            return (
                                                                <TableRow
                                                                    role="checkbox"
                                                                    aria-checked={isItemSelected}
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    selected={isItemSelected}
                                                                    sx={{ cursor: 'pointer' }}
                                                                >
                                                                    <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                                                        <Checkbox
                                                                            icon={<img src="/Checkbox.svg" />}
                                                                            disabled={data.status == "paid" ? true : false}
                                                                            checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                            onClick={(event) => handleClick(data.id, data.transaction_amount)}
                                                                            color="primary"
                                                                            checked={isItemSelected}
                                                                            inputProps={{
                                                                                'aria-labelledby': labelId,
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ cursor: "pointer", borderBottom: "0px solid" }}>
                                                                        {moment(data.created_at).format('DD-MMM-YYYY')}
                                                                    </TableCell>
                                                                    <TableCell style={{ borderBottom: "0px solid" }}>
                                                                        {data.reference}

                                                                    </TableCell>
                                                                    <TableCell style={{ borderBottom: "0px solid" }}>
                                                                        <Box className={styles.imgContean}>
                                                                            <Typography>{data.payment_channel}</Typography>
                                                                        </Box>
                                                                    </TableCell>

                                                                    <TableCell style={{ borderBottom: "0px solid" }}
                                                                    >
                                                                        {data.description}
                                                                    </TableCell>
                                                                    <TableCell style={{ borderBottom: "0px solid" }}
                                                                    >
                                                                       {data.currency}{' '}{data.transaction_amount}
                                                                    </TableCell>
                                                                    <TableCell style={{ borderBottom: "0px solid" }}
                                                                    >
                                                                        {data.currency + " " + data.balance}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }) :

                                                        <TableRow>
                                                            <TableCell colSpan={7} style={{ borderBottom: "0px solid" }}>
                                                                <TebalNoData text={'No Transaction found'} />
                                                            </TableCell>
                                                        </TableRow>}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>

                                    {
                                        totalSelectedAmount > invoiceBalance ?
                                            <p className={styles.limitExceed}>
                                                The total payin amount has exceeded the invoice amount. Please adjust your selections so the total pay-ins do not exceed the invoice amount
                                            </p>
                                            : ""
                                    }

                                    <div style={{ paddingTop: '10px', justifyContent: "space-between" }} className="addnewbtnclass">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className="inpagenum">
                                                <p>
                                                    {remove - 9}-{remove}
                                                </p>
                                            </div>
                                            <div className="btnmaindivin">
                                                {pageneshn == 1 ? (
                                                    <button disabled className="nextntmistish">
                                                        <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="nextntmistish"
                                                        onClick={() => {
                                                            setPagelist(pageneshn - 1);
                                                            setRemovedata(remove - 10);
                                                            const endDateApi = !!endDate
                                                                ? moment(endDate).format('YYYY-MM-DD')
                                                                : '';
                                                            const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                                                            getBalanceList(search, categ, customer_name, stateDateApi, endDateApi, pageneshn - 1)
                                                        }}
                                                    >
                                                        <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                )}
                                                {remove >= totalCount ? (
                                                    <button disabled className="nextntmistish">
                                                        <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="nextntmistish"
                                                        onClick={() => {
                                                            setPagelist(pageneshn + 1);
                                                            setRemovedata(remove + 10);
                                                            const endDateApi = !!endDate
                                                                ? moment(endDate).format('YYYY-MM-DD')
                                                                : '';
                                                            const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                                                            getBalanceList(search, categ, customer_name, stateDateApi, endDateApi, pageneshn + 1)
                                                        }}
                                                    >
                                                        <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.buttinsDiv}>
                                            <Button
                                                onClick={() => handleLinkInvoice("cancel")}
                                                className={styles.fast_button_data}>
                                                <Typography className={styles.CancelStyles}>Cancel</Typography>
                                            </Button>
                                            <Button
                                                className={styles.two_button_invoice}
                                                disabled={selected.length > 0 && totalSelectedAmount <= invoiceBalance ? false : true}
                                                onClick={() => {
                                                    handleLinkInvoice("submit");
                                                }}
                                            >

                                                <Typography className={styles.LinkButtonClasss}>Link to an Invoice

                                                </Typography>
                                                {loader == true && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </>

                    }>
                </ListSkeleton>
            </ThemeProvider>
        </>
    )
}
export default ConnectInvoiceTable;

const SearchIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
            stroke="#111111"
            stroke-width="1.1875"
            stroke-miterlimit="1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);