import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import moment from 'moment-timezone';
import "./TransactionDetails.scss";
import { getUser } from "../../../../../../_helpers";
import Modal from 'react-bootstrap/Modal';
import { balanceActions } from "../../../../../../_actions/balance.actions";
import { useDispatch, useSelector } from "react-redux";
import Menu from '@mui/material/Menu';
import { selectAllBalanceLIstData } from "../../../../../../_reducers/balance.reducer";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { WhatsappShareButton, EmailShareButton } from 'react-share';
import { Box, Typography, IconButton, Autocomplete, TextField, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { settingActions } from '../../../../../../_actions/setting.actions';
import { settingSelector } from '../../../../../../_reducers/setting.reducer';
import { paymentJourneyActions } from '../../../../../../_actions/paymentJourney.actions';

interface Props {
  onClose: any;
  show: boolean;
  data: any;
  resetData?: () => void;
}
interface Team {
  id: number;
  name: string;
}
export const TransactionDetails = (props: Props) => {
  const [option, setOption] = useState<any>({
    id: ''
  });
  const dispatch = useDispatch();
  const [balance, setBalance] = useState<any>();
  const [tagname, settagname] = useState<any>('');
  const [showModal, setShow] = useState(props.show);
  const [dataDisplay, setDataDisplay] = useState(false);
  const [Tagclass, setTagclass] = useState('');
  const [balanceRender, setBalanceRender] = useState(false);
  const [datadesplay, setDatadesplay] = useState(true)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  //Teams data
  const [teams, setTeams] = useState([]);
  const [teamsRender, setTeamsRender] = useState(true);
  const [newTeam, setNewTeam] = useState(false);
  const [newTeamModal, setnewTeamModal] = useState(false);
  const [field_data, setField_data] = useState('');
  const [validesan, setValidesan] = useState(false)
  const [cereteTeam_validet, setCereteTeam_validet] = useState("")
  const [selectedTeamList, setSelectedTeamList] = useState<any>([]);
  const [isHovered, setIsHovered] = useState(false);
  const userData = useSelector((state) => settingSelector(state));
  const [isSaving, setIsSaving] = useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [transactionTeams, setTransactionTeams] = useState<Team[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const popup = searchParams.get('id');
  const saveTeams = async (id: any) => {
    setIsSaving(true);
    console.log('herreeee');
    console.log('save teams payload: ', option);

    try {
      const finalTeams = selectedTeamList?.map((item: any) => {
        return item.id;
      });
      var type = '';
      if (option.Type == 'pay_out') {
        type = 'payout';
      } else if (option.Type === 'pay_in') {
        type = 'payin';
      } else if (option.Type === 'fees') {
        type = 'fees';
      }
      const requestBody = {
        teams: finalTeams,
        type: "app",
        payment_type: type
      };
      await dispatch(balanceActions.updateTransactionTeam(requestBody, id) as any);

      if (!!popup) {
        await dispatch(paymentJourneyActions.PaymentLinkApiList(popup) as any);
      }
      if (props.resetData) {
        props.resetData();
      }
      const initialTeams = option?.teams || option?.payable?.teams || [];
      const selectedTeams = selectedTeamList?.map((item: any) => ({
        id: item.id,
        name: item.name,
      })) || [];
      const updatedTeams = [...initialTeams, ...selectedTeams].reduce((acc: any[], team: any) => {
        if (!acc.some((t) => t.id === team.id)) {
          acc.push(team);  // Only add unique teams
        }
        return acc;
      }, []);
      console.log('rrrrrrrr', updatedTeams);
      console.log('rrrrrrrr2', initialTeams);
      console.log('rrrrrrrr3', selectedTeams);
      setAddTeamModal(false);
      onClose_cerete();
      setTransactionTeams(updatedTeams);

      console.log("Saved team list:", selectedTeamList);
    } catch (error) {
      console.error("Error saving team list", error);
    }
  };
  useEffect(() => {
    // console.log('optionsss', option);
    if (teamsRender && userData?.TEAMDOCUMENT_SUCCESS !== undefined) {
      let options: any = [];
      if (userData.TEAMDOCUMENT_SUCCESS.length > 0) {
        userData.TEAMDOCUMENT_SUCCESS
          .filter((item: any) => item.status === 'active')
          .map((item: any, ind: number) => {
            options.push({
              id: item.id,
              name: item.name,
            });
          });
      }
      setTeamsRender(false);
      setTeams(options);
    }
  }, [userData, teamsRender]);
  const onClose_cerete = () => {
    setAddTeamModal(false);
    setField_data('')
  };
  const ONsave = () => {
    setTimeout(() => {
      setTeamsRender(true)
    }, 4000)
    setCereteTeam_validet("1")
    setField_data('');
    onClose_cerete()
  };
  const Vlidesa = () => {
    setCereteTeam_validet("2")
  };
  const CereteTeam = () => {
    setValidesan(true)
    if (!!field_data) {
      var reqBody = {
        "name": field_data
      }
      dispatch(settingActions.Team_cerete(reqBody, ONsave, Vlidesa) as any)
    }
  };


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(balanceActions.getPdf(props.data.reference) as any);
    setDatadesplay(true)
    setTimeout(() => {
      setBalanceRender(true)
    }, 3000)
  };
  const handleClose_twoo = () => {
    setAnchorEl(null);
  };
  const user = getUser()?.data ?? null;
  const balancelistData = useSelector((state) =>
    selectAllBalanceLIstData(state)
  );
  useEffect(() => {
    setOption(props.data);
    console.log('jjjjjj', props.data);
    const data: any = props.data;
    const newData: any = {}
    setOption(data);
    let tagclass = '';
    if (data.Type == 'pay_in') {
      tagclass = 'tag_payin-tag'
      settagname('Pay In')
    } else if (data.Type == 'pay_out') {
      tagclass = 'tag_payout-tag'
      settagname('Pay Out')
    } else if (data.Type == 'fees') {
      tagclass = 'tag_fees-tag'
      settagname('Fees')
    }
    setTagclass(tagclass);
  }, [props.data]);
  useEffect(() => {
    // console.log('optionsss', option);
    if (balanceRender && balancelistData?.GET_PDF_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(balancelistData.GET_PDF_SUCCESS);
      setDatadesplay(false)
    }
  }, [balancelistData, balanceRender]);
  const onClose = () => {
    setShow(false);
    props.onClose(false);
  };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });


  console.log('modal data =>', option, option?.invoices?.length);


  return (
    <>
      <Modal
        show={showModal}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby='ModalHeader'
        className='topup-modal trans-modal'
        id='afaFafffffFFff'
        centered
      >
        <ThemeProvider theme={theme}>
          <Box className="CloseContenar">
            <IconButton onClick={() => {
              onClose();
            }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="heding_contean">
            <Typography>
              Transaction Details
            </Typography>
          </Box>
        </ThemeProvider>

        <Modal.Body>
          <div className='trans-detail-containerX' >
            <div className='trans-detail-container__table' >
              {
                (!!tagname) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Type</  div>
                    <div className='right text-capitalize tag'>
                      <span className={`tag_color ${Tagclass}`}>
                        {tagname}
                      </span>
                    </div>
                  </div> : ""
              }
              {
                (!!option.reference) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Reference</div>
                    <div className='right' style={{overflow:"auto"}}>{option.reference}</div>
                  </div> : ""
              }
              {
                (!!option.payment_channel) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Payment Channel</div>
                    <div className='right'>{option.payment_channel}</div>
                  </div> : ""
              }
              {
                (!!option.created_at) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Transaction Date</  div>
                    <div className='right'>{moment.tz(option.created_at, "UTC").tz(moment.tz.guess()).format('D MMMM YYYY, h:mm A')}</div>
                  </div> : ""
              }
              {
                (!!option.transaction_amount) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Transaction Amount</div>
                    <div className='right'>{option.currency} {!!option.transaction_amount ?
                      Number((parseFloat((option.transaction_amount))).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : 0}</div>
                  </div> : ""
              }
              {!!option.mobile && tagname == 'Pay In' && !!option.payee ?
                <div className='dflex b-bot'>
                  <div className='left'>Sender Name</div>
                  <div className='right'>{option.payee}</div>
                </div> : ""}
              {!!option.mobile && tagname == 'Pay In' ?

                <div className='dflex b-bot'>
                  <div className='left'>Sender Number</div>
                  <div className='right'>{!!option.mobile ? option.mobile : ""}</div>
                </div>
                : ""
              }
              {tagname == 'Pay Out' ?
                (!!option.payee) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Payee Name</div>
                    <div className='right'>{option.payee}</div>
                  </div> : "" : (!!option.beneficiary) ? <div className='dflex b-bot'>
                    <div className='left'>To</div>
                    <div className='right'>{tagname == 'Pay In' ? option.beneficiary : option.payee}</div>
                  </div> : ""
              }
              {option.payment_channel == "M-Pesa Paybill" && tagname == 'Pay Out' && !!option.beneficiary_public_name ?
                <div className='dflex b-bot'>
                  <div className='left'>Beneficiary Name</div>
                  <div className='right'>{option.beneficiary_public_name}</div>
                </div> : ""}
              {tagname == 'Fees' && !!option.beneficiary ?
                <div className='dflex b-bot'>
                  <div className='left'>Payee Name</div>
                  <div className='right'>{option.beneficiary}</div>
                </div> : ""}
              {option.payment_channel == "M-Pesa Send Money" && tagname == 'Pay Out' && !!option.beneficiary_public_name ?
                <div className='dflex b-bot'>
                  <div className='left'>Beneficiary Name</div>
                  <div className='right'>{option.beneficiary_public_name}</div>
                </div> : ""}
              {option.payment_channel == "M-Pesa Till" && tagname == 'Pay Out' && !!option.beneficiary ? <>

                <div className='dflex b-bot'>
                  <div className='left'>Beneficiary Name</div>
                  <div className='right'>Merchant Till</div>
                </div>
              </> : ""}
              {!!option.bank_name && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                <div className='dflex b-bot'>
                  <div className='left'>Bank Name</div>
                  <div className='right'>{option.bank_name}</div>
                </div> : ""}
              {!!option.account_name && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                <div className='dflex b-bot'>
                  <div className='left'>Payee Name</div>
                  <div className='right'>{option.account_name}</div>
                </div> : ""}
              {!!option.account_number && tagname == 'Pay Out' && option.payment_channel == "Pesalink" ?
                <div className='dflex b-bot'>
                  <div className='left'>Beneficiary Number</div>
                  <div className='right'>{option.account_number}</div>
                </div> : ""}

              {!!option.mobile && tagname == 'Pay Out' && option.payment_channel == "M-Pesa Send Money" ?
                <>
                  <div className='dflex b-bot'>
                    <div className='left'>Beneficiary Number</div>
                    <div className='right'>{!!option.mobile ? option.mobile : ""}</div>
                  </div></> : ""
              }
              {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Paybill' && !!option.paybill_number ?
                <>
                  <div className='dflex b-bot'>
                    <div className='left'>Beneficiary Paybill Number</div>
                    <div className='right'>{!!option.paybill_number ? option.paybill_number : ""}</div>
                  </div></> : ""}
              {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Paybill' && !!option.account_number ?
                <>
                  <div className='dflex b-bot'>
                    <div className='left'>Account Number</div>
                    <div className='right'>{!!option.account_number ?
                      option.account_number : ""}</div>
                  </div></> : ""}
              {option.payment_channel == "M-Pesa Paybill" ||
                option.payment_channel == "M-Pesa Till" ? (
                <div className="dflex b-bot">
                  <div className="left">Account Name</div>
                  <div className="right">{option?.verified_account_name ?? "N/A"}</div>
                </div>
              ) : (
                ""
              )}
              {tagname == 'Pay Out' && option.payment_channel == 'M-Pesa Till' && !!option.till_number ?
                <>
                  <div className='dflex b-bot'>
                    <div className='left'>Beneficiary Number</div>
                    <div className='right'>{!!option.till_number ? option.till_number : ""}</div>
                  </div></> : ""}
              {!!option.mobile && tagname == 'Pay Out' && !!user.entity.name ?
                <div className='dflex b-bot'>
                  <div className='left'>From</div>
                  <div className='right'>{user.entity.name}</div>
                </div> : ""}
              {user?.role_name == 'Developer' ?
                <>
                  {tagname == 'Pay Out' || tagname == 'Pay In' ?
                    <div className="dflex b-bot">
                      <div className="left">Third Party Reference 1</div>
                      <div className="right">{!!option.third_party_reference_1 ? option.third_party_reference_1 : "-"}</div>
                    </div> : ""}
                  {tagname == 'Pay Out' || tagname == 'Pay In' ?
                    <div className="dflex b-bot">
                      <div className="left">Third Party Reference 2</div>
                      <div className="right">{!!option.third_party_reference_2 ? option.third_party_reference_2 : "-"}</div>
                    </div> : ""}
                </> : ""}
              {
                (!!option.invoice_no) ?
                  <div className='dflex b-bot'>
                    <div className='left'>Invoice No</div>
                    <div className='right' style={{ cursor: "pointer", textDecoration: "underline", fontWeight: "bold" }} id='adaDdADddd' onClick={() => {
                      if (!!option.payable_id) {
                        window.location.href = `bills?id=${option.payable_id}`
                      }
                    }}>{option.invoice_no}</div>
                  </div> : ""
              }
              {/* {
  !!option.team ? (
    (tagname === 'Pay Out') ? (
      <div className="dflex b-bot">
        <div className="left">Teams</div>
        <div
          className="right"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
          id="adaDdADddd"
        >
          {option.teams?.map((data: any, index: any) => (
            <Typography key={index} className="two_text_conteanana">
              {`${data.name}${index === option?.teams?.length - 1 ? "" : ", "}`}
            </Typography>
          ))}
        </div>
      </div>
    ) : null
  ) : (
    (tagname === 'Pay Out') && user?.role_name === 'Director' ? (
      <div className="dflex b-bot">
        <div className="left">Teams</div>
        <div>
          <div
            style={{
              margin: "10px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="right"
          >
          
            {option.teams?.length > 0 ? 
    option.teams.map((data: any, index: any) => (
        <Typography key={index} className="two_text_conteanana">
            {`${data.name}${index === option?.teams?.length - 1 ? "" : ", "}`}
        </Typography>
    )) 
    : "-"
} */}
              {
                (tagname === 'Pay Out' || tagname === 'Fees') ? (
                  <div className="dflex b-bot" >
                    <div className="left">Teams</div>
                    <div
                      className="right"
                      style={{
                        display: "flex",
                        gap: "2px",
                        fontWeight: "bold",
                        flex: 1,
                        flexWrap: "wrap"
                      }}
                      id="adaDdADddd"

                    >
                      {transactionTeams?.length > 0 ? (
                        transactionTeams.map((data: any, index: any) => (
                          <Typography
                            key={index}
                            className="right"
                          // style={{ marginTop: "10px" }}
                          >
                            {`${data.name}${index === transactionTeams.length - 1 ? " " : ",    "}`}
                          </Typography>
                        ))
                      ) : option?.payable?.teams?.length > 0 ? (
                        option.payable.teams.map((data: any, index: any) => (
                          <Typography
                            key={index}
                            className="right"
                          // style={{ marginTop: "10px" }}
                          >
                            {`${data.name}${index === option.payable.teams.length - 1 ? " " : ",    "}`}
                          </Typography>
                        ))
                      ) : (
                        <Typography
                          className="right"
                          // style={{ marginLeft: "15%" }}
                        >
                          -
                        </Typography>
                      )}
                      {tagname === 'Pay Out' && (
                        <Button
                          style={{ fontSize: "12px", marginLeft: "20px", height: "25px" }}
                          className="complete-profile__ADDBTNHHA"
                          onClick={() => {
                            setAddTeamModal(true);
                          }}
                        >
                          + Add team
                        </Button>
                      )}
                    </div>
                  </div>
                ) : tagname === 'Pay In' ? (
                  <div className="dflex b-bot">
                    <div className="left">Teams</div>
                    <div
                      className="right"
                      style={{
                        // marginLeft: "20px",
                        fontWeight: "bold",
                      }}
                      id="adaDdADddd"
                    >
                      {transactionTeams?.length > 0 ? (
                        transactionTeams.map((data: any, index: any) => (
                          <Typography
                            key={index}
                            style={{
                              display: "inline-flex",
                            }}
                            className="right"
                          >
                            {`${data.name}${index === transactionTeams.length - 1 ? " " : ", "}`}
                          </Typography>
                        ))
                      ) : option?.teams?.length > 0 ? (
                        option.teams.map((data: any, index: any) => (
                          <Typography
                            key={index}
                            style={{
                              display: "inline-flex",
                            }}
                            className="right"
                          >
                            {`${data.name}${index === option.teams.length - 1 ? " " : ", "}`}
                          </Typography>
                        ))
                      ) : (
                        "-"
                      )}
                      <Button
                        style={{ fontSize: "12px", marginLeft: "20px", height: '25px' }}
                        className="complete-profile__ADDBTNHHA"
                        onClick={() => {
                          setAddTeamModal(true);
                        }}
                      >
                        + Add team
                      </Button>
                    </div>
                  </div>
                ) : null
              }
              {/* {option?.invoices?.length == 0 || !option?.invoices ? "" :
                <div className="dflex b-bot">
                  <div className="left">Invoice No</div>
                  <div className="right">
                    
                  </div>
                </div>} */}
              {option?.Type == 'pay_in' ?
                <div className="dflex b-bot" >
                  <div className="left">Invoice No</div>
                  <div
                    className="right"
                    style={{
                      // marginLeft: "20px",
                      fontWeight: "bold",
                    }}
                    id="adaDdADddd"
                  >
                    {option?.invoices?.length == 0 && option?.Type == 'pay_in' ?
                      <>
                        {option?.invoices?.length > 0 ? (
                          option.invoices.map((data: any, index: any) => (
                            <Typography
                              key={index}
                              style={{
                                display: "inline-flex",
                              }}
                              className="right"
                            >
                              {`${data.name}${data.invoice_no}${index === option.invoices.length - 1 ? " " : ", "}`}
                            </Typography>
                          ))
                        ) : (
                          "-"
                        )}
                        <Button
                          style={{ fontSize: "12px", marginLeft: "20px", height: '25px' }}
                          className="complete-profile__ADDBTNHHA"
                          disabled={user.role_name == "Auditor" ? true : false}
                          onClick={() => {
                            onClose()
                            navigate(
                              "/transaction/connect-to-invoice?id=" + option.id + "&currency=" + option.currency + "&transactionAmount=" + option.transaction_amount,
                              { replace: true, state: { id: option.id, currency: option.currency, transactionAmount: option.transaction_amount } }
                            );
                          }}
                        >
                          Connect Invoice
                        </Button>

                      </> : <>

                        {option?.invoices?.map((item: any, index: any) => {
                          {
                            console.log(index, option?.invoices?.length, "itemitemitem");
                          }
                          return (
                            <div onClick={() => {
                              navigate("/invoice?id=" + item.monite_invoice_id, { replace: true, state: { id: item.monite_invoice_id } })
                            }}>
                              {item?.invoice_no}{option?.invoices?.length == index + 1 ? "" : ','}{' '}
                            </div>
                          )
                        })}</>}

                  </div>
                </div> : ""}

              {addTeamModal ? (
                <ThemeProvider theme={theme}>
                  <Modal
                    show={addTeamModal}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    centered
                  ><Modal.Body>
                      <Box className="box_dialog_main">
                        <Typography className="Create_Team_heding">{"Select Team"}</Typography>
                        <div id="fast_name_inpurt_div" >
                          <Autocomplete
                            multiple
                            sx={{
                              width: "50%",
                              marginLeft: "100px",
                              marginTop: "10px",
                              "& .MuiInputBase-root": {
                                display: "flex",
                                alignItems: "center",
                                background: "#FFFFFF",
                                border: "0px solid #DDDDDD",
                                outline: "none",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#111111",
                                borderRadius: "8px",
                                padding: "7px 9px",
                              },
                            }}
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={teams}
                            value={selectedTeamList}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, newValue) => {
                              setSelectedTeamList(newValue);
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                          />
                        </div>

                        <Box className={"box_btn_cencal"} sx={{ justifyContent: "space-between", display: "flex" }}>
                          <Button
                            className={"cerete_user"}
                            onClick={() => { onClose_cerete() }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            style={{ backgroundColor: isHovered ? '#f0f0f0' : 'transparent', borderColor: isHovered ? '#025041' : '' }}>
                            Cancel
                          </Button>
                          <Button className={"savebtn"} style={{ marginLeft: "15px", height: "40px" }}
                            onClick={() => {
                              saveTeams(option.id)
                            }}>Save</Button>
                        </Box>
                      </Box></Modal.Body></Modal >
                </ThemeProvider>

              ) : (
                " "
              )}



            </div>
          </div>
          <ThemeProvider theme={theme}>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose_twoo}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{ "& .MuiMenu-list": { padding: "0px" } }}
            >
              <div style={{ padding: "5px" }}>
                <div style={{ height: "27px" }}>
                  {!!balance && !!balance.data.path && datadesplay == false ?
                    <a style={{ textDecoration: "none" }}
                      href={!balance ? "" : balance.data.url}
                      className='download_buttoun' target="_blank">Download PDF</a> :
                    <a style={{ textDecoration: "none", cursor: "no-drop" }}
                      className='download_buttoun'>Download PDF</a>}
                </div>
                <div style={{ height: "27px" }}>
                  <WhatsappShareButton
                    disabled={!!balance && !!balance.data.path && balanceRender == false ? false : true}
                    url={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${!balance ? "" : balance.data.path}${'\n'}To also use Niobi to easily track your finances and business payments, sign up here: https://app.niobi.co${'\n'}`}
                    onClick={handleClose_twoo}
                  >
                    Send via WhatsApp
                  </WhatsappShareButton>
                </div>
                <div style={{ height: "27px" }}>
                  <EmailShareButton
                    disabled={!!balance && !!balance.data.path && balanceRender == false ? false : true}
                    url={`To also use Niobi to easily track your finances and business payments, sign up here: https://app.niobi.co${'\n'}`}
                    body={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${!balance ? "" : balance.data.path}${'\n'}`}
                  >
                    Send via Email
                  </EmailShareButton>

                </div>
              </div>
            </Menu>
          </ThemeProvider>
          <div className='trans-detail-container__btn-row'>
            {/* {tagname == 'Pay In' ? <Button className='trans-detail-container__btn-row__green'
                            onClick={() => {
                                onClose()
                                navigate("/PaymentInvoice")
                                }}>
                            Link to an invoice
                        </Button> : <></>
                        } */}
            <Button className='trans-detail-container__btn-row__green'
              onClick={handleClick}
            >Share</Button>
            {/* {option?.invoices?.length == 0 && option?.Type == 'pay_in' && user.role_name != "Auditor" &&
              <Button className='trans-detail-container__btn-row__green'
              >
                
              </Button>
            } */}
          </div>
        </Modal.Body>
      </Modal >

    </>
  )
}