import React, { useEffect, useState } from 'react';
import Loader from '../../Settings/components/Profile/components/AccountSetup/Loader';
import { CircularProgress } from '@mui/material';

const FeedbackModal = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'eu1',
          portalId: '26625235',
          formId: '246c44f1-17b6-407e-9f89-ce725ecac110',
          target: '#hubspotForm',
          onFormReady: () => {
            setIsLoading(false); // Hide the loader once the form is ready
          },
        });
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="loader">
          <CircularProgress color="success" />
        </div>
      )}
      <div id="hubspotForm" style={{ display: isLoading ? 'none' : 'block',  marginBottom: "20px" }} />
    </div>
  );
};

export default FeedbackModal;
