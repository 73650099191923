import React from 'react';
import { navigationData } from './consts';
//import { navigationData } from 'features/app/Layout/Menu/consts';
import { Box, Button, Typography } from '@mui/material';
// import * as Styled from './styles';
import styles from './menu.module.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTour } from '@reactour/tour';
import { getUser } from '../../../../_helpers';
import { settingSelector } from '../../../../_reducers/setting.reducer';
import { useDispatch, useSelector } from 'react-redux';
import ProductionSelectionModal from '../Settings/components/Profile/components/AccountSetup/ProductSelectionModal';
import { useNavigate } from 'react-router-dom';
import { settingActions } from '../../../../_actions/setting.actions';
import Modal from 'react-bootstrap/Modal';
import VerifiedPopup from '../Settings/components/Profile/components/VerifiedPopup';
import { Model_Reject } from '../Settings/components/Profile/components/Model_Reject';
import { ModelumentVerified } from '../Settings/components/Profile/components/modelsoon';
import ApprovalsModel from './Model/ApprovalsModel';
import { dispatchStore } from '../../../../Store/store';
import { userActions } from '../../../../_actions';
const Menu_List = ({ setState, setShow, setFeedbackModal }: any) => {
  const pathname = window.location.pathname;
  // console.log(Object.values(navigationData), 'Object.values(navigationData)');
  const iconColor = '#025041';
  // console.log(pathname, 'pathnamepathname');
  const userDetails = localStorage.getItem('user');
  const [expanded, setExpanded] = React.useState<string | false>('yes');
  const user = getUser()?.data ?? null;
  // const userData = useSelector((state) => settingSelector(state));
  const [productSelectionModal, setProductSelectionModal] =
    React.useState(false);
  const [ApprovalsSelectionModal, setApprovalsSelectionModal] =
    React.useState(false);
  const [renderEntity_two, setRenderEntity_two] = React.useState(false);
  const dataEntity: any = useSelector((state) => settingSelector(state));
  const [status, setstatus] = React.useState('');
  // const [renderEntity, setRenderEntity] = React.useState(true);
  const [render, setRender] = React.useState(true);
  const [users, setUsers] = React.useState<any>();
  const [loader, setLoader] = React.useState(false);
  const { setIsOpen } = useTour();
  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };
  // console.log(user, 'useruser');
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const LogoutApi = () => {
    dispatchStore(
      userActions.logout('logout', 'Logged out successfully') as any
    );
  };
  const extractLabelText = (label: string | React.ReactNode): string => {
    if (typeof label === 'string') {
      // Return string labels directly
      return label.trim();
    }
  
    if (React.isValidElement(label)) {
      // Recursively extract children from JSX elements
      return React.Children.toArray(label.props.children)
        .map((child) => {
          if (typeof child === 'string') {
            const text = child.trim();
            return text !== '' ? text : ''; // Return only non-empty text
          }
          if (React.isValidElement(child)) {
            // Recursively extract label text from nested JSX elements
            return extractLabelText(child); // This will naturally return only non-empty strings
          }
          return ''; // Ignore non-text content
        })
        .join('') // Combine all extracted text (if multiple children)
        .trim(); // Trim the final result to avoid leading/trailing spaces
    }
  
    return ''; // Return empty string if label is neither string nor valid React element
  };
  
  
  
  // const onClose = () => {
  //   setShow(false);
  // };
  React.useEffect(() => {
    if (!!user && render) {
      setRender(false);
      setUsers(getUser);
      dispatch(settingActions.getEntityDocuments() as any);
      setRenderEntity_two(true);
      setLoader(true);
    }
  }, [render, user]);
  React.useEffect(() => {
    if (
      renderEntity_two &&
      dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined
    ) {
      if (!!dataEntity.GETENTITYDOCUMENT_SUCCESS.data) {
        var GetData = dataEntity?.GETENTITYDOCUMENT_SUCCESS?.data;
        setLoader(false);
        // setType(GetData.document_type);
        // setUserDetail(true);

        setstatus(GetData.status);
      }
      setRenderEntity_two(false);
    }
  }, [renderEntity_two, dataEntity]);

  const storedApprovalModal = localStorage.getItem('approval_modal');
  const approvalModal = storedApprovalModal
    ? JSON.parse(storedApprovalModal)
    : false;

  return (
    <>
      {/* <Styled.Menu> */}
      {Object.values(navigationData).map((item: any, index) => {
        // console.log(item, 'sdzfsffffff');
        return (
          <>
            {item.label === 'Inflows' ||
            item.label === 'Outflows' ||
            item.label === 'Tools' ||
            item.label === 'Workspace' ||
            item.label === 'Get Help' ? (
              <Accordion
                defaultExpanded={
                  item.label === 'Inflows' || item.label === 'Outflows'
                    ? true
                    : false
                }
                // expanded={expanded==== item.label}
                // onChange={handleChange(item.label)}
                className={styles.Accordion_daatatatta}
              >
                <AccordionSummary
                  id={
                    item.label === 'Workspace'
                      ? 'Workspace_list'
                      : item.label === 'Get Help'
                      ? 'Get_Help_list'
                      : ''
                  }
                  className={styles.batninnitmenu_twoo}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <div>{item?.Icon}</div> <p>{item.label}</p>
                </AccordionSummary>
                <AccordionDetails className={styles.jhduhaudhdahddh}>
                  {item?.children?.map((item: any, index: any) => {
                    const labelText = extractLabelText(item.label);
                    console.log('labelText', labelText);
                    return (
                      <>
                      
                        {item === '' ? (
                          ''
                        ) : (
                          
                          <Button
                            id={
                              labelText == 'Invoices'
                                ? 'invoice_product_tool'
                                : labelText == 'Payment links'
                                ? 'payment_link_log'
                                : labelText == 'Bills'
                                ? 'thred_log'
                                : labelText == 'Approvals'
                                ? 'aporuwal_data'
                                : labelText == 'Payees'
                                ? 'fast_secend_log'
                                : labelText == 'Categories'
                                ? 'categories_log'
                                : labelText == 'Customers'
                                ? 'customers_log'
                                : labelText == 'Products'
                                ? 'products_log'
                                : styles.submenu_id
                            }
                            className={
                              pathname === item?.url
                                ? styles.active_btn_sub
                                : styles.batninnitmenu_sub
                            }
                            onClick={() => {
                              // CloseList
                              setState({
                                left: false,
                              });
                              if (item.label === 'Account Setup') {
                                if (status === 'waiting_for_submission') {
                                  navigate('/settings/account-setup');
                                } else {
                                  // navigate('/settings/account-setup/verification');
                                  navigate('/settings/document-upload', {
                                    state: {
                                      selectedType:
                                        !!userDetails &&
                                        JSON.parse(userDetails).data.entity
                                          ?.entity_type_id,
                                    },
                                  });
                                }
                              } else if (item.label === 'Product Tour') {
                                navigate('/dashboard');
                                setIsOpen(true);
                                // localStorage.setItem('newUser', 'true');
                              } else if (item.label === 'Approvals') {
                                setApprovalsSelectionModal(true);
                                localStorage.setItem(
                                  'approval_modal',
                                  JSON.stringify(true)
                                );
                              } else {
                                navigate(item.url);
                              }
                            }}
 >
                            <div>{item?.Icon}</div>{' '}
                            <p>{item?.label ? item?.label : ''}</p>
                          </Button>
                        )}
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : item === '' ? (
              ''
            ) : (
              <Button
                id={
                  item.label === 'Dashboard'
                    ? 'fast_list_log'
                    : item.label === 'My accounts'
                    ? 'Balance_list'
                    : item.label === 'Logout'
                    ? 'Logout'
                    : ''
                }
                className={
                  pathname === item.url
                    ? styles.active_btn
                    : styles.batninnitmenu
                }
                onClick={() => {
                  // setState({
                  //   left: false,
                  // })

                  if (item.label === 'Logout') {
                    setShow(true);
                    setState({
                      left: false,
                    });
                  } else if (item.label === 'Feedback') {
                    setFeedbackModal(true);
                    setState({
                      left: false,
                    });
                  } else {
                    setState({
                      left: false,
                    });
                    navigate(item.url);
                  }
                }}
              >
                <div>{item.Icon}</div> <p>{item.label}</p>
              </Button>
            )}
          </>
        );
      })}
      {/* </Styled.Menu> */}
      {/* <Modal
        show={showModal}
        onHide={()=>{}}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        // className='topup-modal'
        centered
      // size="sm"
      >
        <Modal.Body>
          <Box className={styles.box_dialog_main}>
            <Typography className={styles.typo_text}>
              Do you wish to proceed to logout?
            </Typography>
            <Box className={styles.box_btn_cencal}>
              <Button className={styles.cancel_btn} 
              // onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className={styles.delete_btn}
                onClick={() => {
                  // onClose();
                  LogoutApi();
                }}
              >
                Yes, logout
              </Button>
            </Box>
          </Box>
        </Modal.Body>
      </Modal> */}
      {(ApprovalsSelectionModal || approvalModal) && (
        <>
          <Modal
            show={ApprovalsSelectionModal || approvalModal}
            onHide={() => {
              setApprovalsSelectionModal(false);
              localStorage.setItem('approval_modal', JSON.stringify(false));
            }}
            backdrop="static"
            keyboard={false}
            aria-labelledby="ModalHeader"
            className="topup-modal"
            centered
            size="lg"
          >
            <ApprovalsModel
              show={ApprovalsSelectionModal || approvalModal}
              onClose={() => {
                setApprovalsSelectionModal(false);
                localStorage.setItem('approval_modal', JSON.stringify(false));
                // setShowBalanceGrid(true);
              }}
              onSave={(el: boolean) => {
                setApprovalsSelectionModal(false);
                localStorage.setItem('approval_modal', JSON.stringify(false));
                // setCereat_Teabal(true)
              }}
              onconfirm={(el: boolean) => {
                setApprovalsSelectionModal(false);
                localStorage.setItem('approval_modal', JSON.stringify(false));
                // setIsOpen(true)
              }}
            />
          </Modal>
        </>
      )}

      {productSelectionModal && (
        <>
          <Modal
            show={productSelectionModal}
            onHide={() => {
              setProductSelectionModal(false);
            }}
            backdrop="static"
            keyboard={false}
            aria-labelledby="ModalHeader"
            className="topup-modal"
            centered
            size="lg"
          >
            {status === 'waiting_for_submission' ? (
              <ProductionSelectionModal
                show={productSelectionModal}
                onClose={() => {
                  setProductSelectionModal(false);
                  // setShowBalanceGrid(true);
                }}
                onSave={(el: boolean) => {
                  setProductSelectionModal(false);
                  // setCereat_Teabal(true)
                }}
                onconfirm={(el: boolean) => {
                  setProductSelectionModal(false);
                  // setIsOpen(true)
                }}
              />
            ) : status === 'approved' &&
              users?.data?.entity?.status === 'VERIFIED' ? (
              <VerifiedPopup
                page="fastpage"
                onSave={() => {
                  setProductSelectionModal(false);
                }}
              />
            ) : status === 'rejected' ? (
              <Model_Reject
                onSave={() => {
                  setProductSelectionModal(false);
                }}
              />
            ) : status === 'waiting_for_approval' ? (
              <ModelumentVerified
                page="fastpage"
                onSave={() => {
                  setProductSelectionModal(false);
                }}
              />
            ) : (
              ''
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default Menu_List;
