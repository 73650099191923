
import { Button } from "react-bootstrap";
import styles from './ui.module.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const ButtonSave = ({ text, onClick, style, loader }: any) => {
    return (
        <>
            <Button style={style} className={styles.saveButton} onClick={onClick}>
                {text}
                <span style={{ paddingLeft: "3px" }}>
                    {loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
                </span>
            </Button>
        </>
    )
}
export const GoBackButton = ({ onClick, style }: any) => {

    return (
        <Button style={style} className={styles.GOback_button} onClick={onClick}>
            <KeyboardBackspaceIcon /> Go Back
        </Button>
    )
}
export const AddButton = ({ children, onclickset }: any) => {

    return (
        <Button className={styles.ButtonClassnormal} onClick={onclickset}>
            {children}
        </Button>
    )
}
export const CreateButton = ({ children, setonClick, loader, type, style,disabled }: any) => {

    return (
        <Button type={type} disabled={disabled} className={styles.createButtonstyle} onClick={setonClick} style={{ ...style }}>
            <span style={{ paddingLeft: "3px" }}>
                {loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
            </span>
            {children}
        </Button>
    )
}
export const SaveButton = ({ children, setonClick, loader, type, style }: any) => {

    return (
        <Button type={type} className={styles.SaveButtonstyle} onClick={setonClick} style={{ ...style }}>
            <span style={{ paddingLeft: "3px" }}>
                {loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
            </span>
            {children}
        </Button>
    )
}
export const DeleteButton = ({ children, setonClick, style,loader }: any) => {

    return (
        <Button className={styles.DeleteButtonstyle} onClick={setonClick} style={{ ...style }}>
           <span style={{ paddingLeft: "3px" }}>
                {loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
            </span>
            {children}
        </Button>

    )
}