import { Box, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from './connectTransactions.module.scss';
import TransactinTable from './connectTransactionTable';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ConnectTransaction = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [transactionId, setTransactionId] = useState([])
    const [transactionCurrency, setTransactionCurrency] = useState([])
    const [transactionAmountdata, setTransactionAmountdata] = useState([])
    const navigate = useNavigate();

    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });

    useEffect(() => {
        const id: any = searchParams.get('id');

        const currency: any = searchParams.get('currency');
        const transactionAmount: any = searchParams.get('transactionAmount');
        setTransactionId(id);
        setTransactionCurrency(currency);
        setTransactionAmountdata(transactionAmount)
    }, [searchParams]);


    return (
        <>
            <ThemeProvider theme={theme}>
                <>
                    <Box className={styles.ContenarInvoice}>
                        <Box>
                            <Typography className={styles.typo_heding}>Connect Payment with Invoice</Typography>
                        </Box>
                        <Box sx={{ height: "46px" }}></Box>
                    </Box>
                    <Box className={styles.ContenarTebal}>
                        <Button
                            className={styles.GoBACKBUTTONTYLE}
                            onClick={() => {
                                navigate("/invoice")

                            }}
                        >
                            <KeyboardBackspaceIcon />
                            Go Back
                        </Button>
                        <TransactinTable transactionData={{ transactionId, transactionCurrency, transactionAmountdata }} />
                    </Box>
                </>
            </ThemeProvider>
        </>
    )
}
export default ConnectTransaction;