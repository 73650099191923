import { Box, Grid, IconButton, Typography } from "@mui/material";
import styles from "./setp4.module.scss";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
// import Tebal from './components/tebal/tebal';
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import {
  getUser,
  isUserAuthenticated,
  authHeader,
} from "../../../../../../_helpers";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CircularProgress } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ShareIcon from "@mui/icons-material/Share";
import moment from "moment";
import Tebal from "./tebal";

const Setp4 = ({data}:any) => {
  const [pageneshn, setPagelist] = useState(1);
  const [remove, setRemovedata] = useState(10);
  const [totalCount, setTotalCount] = useState(10);
  const [row, setRow] = useState([]);
  const [shareLink, setSreeaLink] = useState("");
  const [anchorElWhatsApp, setAnchorElWhatsApp] = React.useState(null);
  const [ideditdata, setIdeditdata] = React.useState("");

  return (
    <>
      <Box className={styles.box_tebalbottom}>
        <Tebal data={data.payment_links} />
      </Box>
      {/* <div className="addnewbtnclass" style={{ paddingTop: "10px",padding:"16px" }}>
        <div className="inpagenum">
          <p>
            {remove - 9}-{remove}
          </p>
        </div>
        <div className="btnmaindivin">
          {pageneshn == 1 ? (
            <button disabled className="nextntmistish">
              <ArrowBackIosNewIcon style={{ fontSize: "16px" }} />
            </button>
          ) : (
            <button
              className="nextntmistish"
              onClick={() => {
                setPagelist(pageneshn - 1);
                setRemovedata(remove - 10);
              }}
            >
              <ArrowBackIosNewIcon style={{ fontSize: "16px" }} />
            </button>
          )}
          {remove >= totalCount ? (
            <button disabled className="nextntmistish">
              <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
            </button>
          ) : (
            <button
              className="nextntmistish"
              onClick={() => {
                setPagelist(pageneshn + 1);
                setRemovedata(remove + 10);
              }}
            >
              <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
            </button>
          )}
        </div>
      </div> */}
    </>
  );
};
export default Setp4;
