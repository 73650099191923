import { Modal } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from "@mui/material/colors";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styles from './csvuploadModel.module.scss';
import React from "react";
import * as XLSX from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const CsvuploadModel = ({ showModal, onClose,onSave,ModalTitle,Modaldes,setp1heding,setp1des,setp2titel,setp2des }) => {
    const [filllist, setFile] = React.useState('');
    const [listcvdata, setlistcvdata] = React.useState([])
    const [csvname, setCsvnamedata] = React.useState("")
    const [selectedMethod, setSelectedMethod] = React.useState('');

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });

      const handleCSVUpload = async (event) => {
            console.log(event.target.files[0].type, "event.target.files[0].type");
            if (event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
    
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
    
                    const jsonData = XLSX.utils.sheet_to_json(sheet);
                    setlistcvdata(jsonData);
                    onSave(jsonData)
                    // localStorage.setItem("Bulk_Payment", JSON.stringify(jsonData))
                    // navigate("/Bulk_Payments")
                };
    
                reader.readAsArrayBuffer(file);
            } else {
                toast.error("Please upload file in .xlsx format only, CSV files are not supported. Thank you!")
            }
        };
    return (
        <>
            <ThemeProvider theme={theme}>

                <Modal
                    show={showModal}
                    onHide={onClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    className='topup-modal'
                    centered
                    size="lg"
                >
                    <Box className="CloseContenar">
                        <IconButton onClick={() => {
                            onClose();
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Modal.Header style={{ padding: "10px 32px 32px 32px" }} className='tophead' closeButton>
                        <Modal.Title>{ModalTitle}</Modal.Title>
                        <Typography className={styles.add_text_key_loaa} style={{ marginTop: '8px', fontSize: "13px" }}>{Modaldes}</Typography>
                    </Modal.Header>
                    <Modal.Body className='modalbbody'>
                        <Grid container spacing={0}>
                            <Grid xs={12} sm={12} md={6} style={{ paddingRight: '10px' }}>
                                <Typography className='headingCus'>{setp1heding}</Typography>
                                <Typography className='subheading mb-3' style={{ fontSize: "13px", lineHeight: "unset" }}>{setp1des}</Typography>
                                <div
                                    className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}
                                >

                                    <a href='/Categories.xlsx' style={{ textDecoration: 'none' }}
                                    >
                                        <div className={styles.addonebtn}>
                                            <div className='type-card__detail'>
                                                Download Template File
                                            </div>
                                            <div className={styles.iconadda + " " + styles.save_btn22}>
                                                <div class="m-2">Download</div>
                                                <div class={styles.download + " "}><MobileIcon /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} style={{ paddingLeft: '10px' }}>
                                <Typography className='headingCus' >{setp2titel}</Typography>
                                <Typography className='subheading' id={styles.afsfaffff} style={{ paddingBottom: '43px', fontSize: "13px" }}>{setp2des}</Typography>
                                <div>
                                    <label for="inputTag" style={{ width: '100%' }} id={filllist == '' ? '' : styles.active} className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}>
                                        <div className={styles.addonebtn}>
                                            <div className='type-card__detail'>{!!filllist && !!filllist.name ? filllist.name : 'Upload File'}</div>
                                            <div className={styles.iconadda + " " + styles.save_btn22}>
                                                <div>Upload</div>
                                                <div ><VisaCardIcon /></div>
                                            </div>
                                        </div>
                                        <input id="inputTag" className={styles.addaffafagga}
                                            type="file" onChange={(file) => {
                                                console.log(file.target.files, 'dhdhhdhhdhhd')
                                                handleCSVUpload(file);
                                                setFile(file);
                                            }}
                                            accept=".csv" />
                                    </label>

                                </div>
                            </Grid>
                        </Grid>

                    </Modal.Body>

                </Modal>
            </ThemeProvider>
        </>
    )
}
const MobileIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
const VisaCardIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);