import styles from './csvlist.module.scss'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import '../topup-modal/Topup.scss';
import CloseIcon from '@mui/icons-material/Close';
// import toast from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

const MobileIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
const VisaCardIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export class HttpClient {
    static get() {
        return Promise.resolve(`Invoice Number*,Payee Name*,Invoice Date*,Due Date*,Amount*,Category*,Description
NIO-001,Please make sure the Payee name entered here exactly matches the name of the Beneficiary in the Niobi App,2005-04-13,2005-04-13,Enter amount in KES,Rent,Enter description`
        );
    }
}
const Topup_balk_data = (props) => {
    const [dataInCSV, setDataInCSV] = React.useState("");
    const [showModal, setShow] = useState(props.show);
    const [selectedMethod, setSelectedMethod] = useState('');
    const [filllist, setFile] = useState('');
    const [show, setShowlist] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [page, setPage] = React.useState(false);
    let navigate = useNavigate();

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const onClose = () => {
        setShow(false);
        props.onClose(false);
    };
    const handleShow = () => {
        // window.location.href = '/Bulktebal'
        setShow(false);
        setShowlist(true);
        setPage(true)
    }

    useEffect(()=>{
        toast.success('Template Updated: Please download and use the new bulk payment template to avoid issues with previous versions.');
    },[])

    const handleFileChange = async (event) => {
        console.log(event.target.files[0].type, "event.target.files[0].type");
        if (event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet);
                setExcelData(jsonData);
                handleShow()
                localStorage.setItem("Bulk_Payment", JSON.stringify(jsonData))
                navigate("/Bulk_Payments")
            };

            reader.readAsArrayBuffer(file);
        } else {
            toast.error("Please upload file in .xlsx format only, CSV files are not supported. Thank you!")
        }
    };
    React.useEffect(() => {
        setPage(false)
        HttpClient.get().then((res) => setDataInCSV(res));
    }, []);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Modal
                    show={showModal}
                    onHide={onClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    className='topup-modal'
                    centered
                    size="lg"
                >
                    <Modal.Header style={{ padding: "10px 32px 32px 32px" }} className='tophead' closeButton>
                        <Box className="CloseContenar">
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Modal.Title>Bulk Payments</Modal.Title>

                        <Typography className={styles.add_text_key_loaa} style={{ marginTop: '8px', fontSize: "13px" }}>Follow the steps below to complete bulk payment</Typography>

                    </Modal.Header>

                    <Modal.Body className='modalbbody'>
                        <Grid spacing={0}>
                            <Grid xs={12} sm={12} md={6}>
                                <Typography className='headingCus'>Step 1
                                <span>
                                 <Tooltip title="Template Updated: Please download and use the new bulk payment template to avoid issues with previous versions.">
                                <Button className='toolTip_bulk_payment_Step_one'>
                                    <InfoIcon></InfoIcon>
                                </Button>
                               </Tooltip>
                                </span>
                                </Typography>
                                <Typography className='subheading mb-3' style={{ fontSize: "13px" }}>Fill in the details and save the file</Typography>
                                <div
                                    className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}
                                >
                                    <a href='/Niobi Bulk Payment Template.xlsx' style={{ textDecoration: 'none' }}
                                    >

                                        <div className={styles.addonebtn}>
                                            <div className='type-card__detail'>
                                                Download Template File
                                            </div>
                                            <div className={styles.iconadda + " " + styles.save_btn22}>
                                                <div class="m-2">Download</div>
                                                <div class={styles.download + " "}><MobileIcon /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} className='mt-4'>
                                <Typography className='headingCus' >Step 2</Typography>
                                <Typography className='subheading' id={styles.afsfaffff} style={{ paddingBottom: '14px', fontSize: "13px" }}>Please upload the saved file</Typography>
                                <div>
                                    <label for="inputTag" style={{ width: '100%' }} id={filllist == '' ? '' : styles.active} className={`type-card ${selectedMethod === 'mobile' ? 'active' : ''}`}>
                                        <div className={styles.addonebtn}>
                                            <div className='type-card__detail'>{!!filllist && !!filllist.name ? filllist.name : 'Upload File'}</div>
                                            <div className={styles.iconadda + " " + styles.save_btn22}>
                                                <div class="m-2">Upload</div>
                                                <div class={" "}><VisaCardIcon /></div>
                                            </div>
                                        </div>
                                        <input id="inputTag" className={styles.addaffafagga} type="file" onChange={(file) => {
                                            console.log(file.target.files, 'dhdhhdhhdhhd')
                                            handleFileChange(file);
                                            setFile(file.target.files[0]);

                                        }}
                                            accept=".xlsx" />
                                    </label>

                                </div>
                            </Grid>
                        </Grid>
                    </Modal.Body>
                    {/* <Modal.Footer>
                    <Button className='white-btn' onClick={onClose}>Cancel</Button>
                    <Button id={styles.button_bills_nextt} className='green-btn' disabled={filllist === ''} onClick={() => {
                       
                    }}>Next</Button>
                </Modal.Footer> */}
                </Modal>
            </ThemeProvider>
        </>


    )
}

export default Topup_balk_data;


const InfoIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 11 11"
        fill="none"
    >
        <path d="M5.3333 7.50039V4.90039" stroke="#025041" stroke-linecap="round" />
        <path
            d="M5.33333 3.16654C5.57265 3.16654 5.76666 3.36055 5.76666 3.59987C5.76666 3.83919 5.57265 4.0332 5.33333 4.0332C5.094 4.0332 4.89999 3.83919 4.89999 3.59987C4.89999 3.36055 5.094 3.16654 5.33333 3.16654Z"
            fill="#025041"
        />
        <path
            d="M3.16666 1.5798C3.82512 1.19886 4.57261 0.998833 5.33333 1.00001C7.72662 1.00001 9.66665 2.94004 9.66665 5.33333C9.66665 7.72663 7.72662 9.66666 5.33333 9.66666C2.94003 9.66666 1 7.72663 1 5.33333C1 4.54423 1.21103 3.80367 1.5798 3.16667"
            stroke="#025041"
            stroke-linecap="round"
        />
    </svg>
);