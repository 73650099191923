import { authHeader,handleResponse } from "../_helpers";


export const PaymentFlowservice = {
    getApiFlow,
    PostPayment,
    getPaymentdelete
}
function getApiFlow(Link) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}${Link}`,
    requestOptions
  ).then(handleResponse);
}
function PostPayment(Link,payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader() },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}${Link}`,
    requestOptions
  ).then(handleResponse);
}
function getPaymentdelete(Link) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}${Link}`,
    requestOptions
  ).then(handleResponse);
}