import { useEffect, useState } from "react";
import Request_Payment_create from "./components/Request_Payment_create/Request_Payment_create";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import { Formik } from "formik";
import { payablesFlowactions } from "../../../../_actions";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { getUser } from "../../../../_helpers";


const CreateRequestPayment = () => {
    const [CostomersId, setCostomersId] = useState('')
    const [loader, setLoader] = useState(false)
    const [SuccessRes, setSuccessRes] = useState<any>('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [CustomerName, setCustomerName] = useState("")

    const [recurringdata, setRecurringdata] = useState({
        recurring_type: "weekly",
        day: "",
        date: "",
        months: "",
        monthsdate: "",
        StopDate: ""
    })
    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });
    const dispatch = useDispatch()
    const user = getUser()?.data ?? null;

    useEffect(() => {
        const CostomersIdparams: any = searchParams.get('CostomersId');
        setCostomersId(CostomersIdparams);
    }, [searchParams]);
    const validationSchema = Yup.object().shape({
        CustomerName: Yup.string()
            // .min(2, "*Names must have at least 2 characters")
            // .max(100, "*Names can't be longer than 100 characters")
            .required("Please select the Customer name."),
        Amount: Yup.string().required("Please enter the Amount."),
        // invoice: Yup.string().required("Please enter the invoice."),
        Currency: Yup.string().required("Please select the Currency."),
        Activeduration: Yup.string().required("Please select the Active duration."),
        SendVia: Yup.string().required("Please select the SendVia."),
        subject: Yup.mixed().when('SendVia', {
            is: 'email',
            then: Yup.mixed().required('Please enter the Subject.'),
            otherwise: Yup.mixed().notRequired(),
        }),
        body_text: Yup.mixed().when('SendVia', {
            is: 'email',
            then: Yup.mixed().required('Please enter the body text.'),
            otherwise: Yup.mixed().notRequired(),
        }),
        recurring: Yup.string().required("Please select the SendVia."),
    });
    return (
        <>
            <div className="balance">
                <div className="balance__header product_btn_wrapper">
                    <div className="balance__header__name">Request Payment</div>
                    <div style={{ height: "46px" }}></div>
                </div>
                <ThemeProvider theme={theme}>
                    <Formik
                        // innerRef={formdata}
                        initialValues={{
                            CustomerName: CostomersId,
                            invoice: [],
                            Amount: '',
                            Currency: "",
                            Activeduration: "",
                            SendVia: '',
                            subject: "Invoice from Niobi",
                            body_text: `Hi ${CustomerName},\nPlease find the invoice attached as discussed.\nKind regards,\n${!!user ? (!!user?.name ? user?.name : '') : ''}`,
                            recurring: false,

                            // document_id: "",
                            // categories: "",
                            // teams: "",
                            // select_flow: "",
                            // // dayformeat: "",
                            // end_date: "",
                            // amount: "0",
                            // numbar_date: "",
                            // month: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            var NewAarry: any = []
                            var body: any = {
                                "customer_id": values.CustomerName,
                                "invoice_ids": NewAarry,
                                "amount": values.Amount,
                                "currency": values.Currency,
                                "send_via": values.SendVia,

                                "recurring": values.recurring == true ? 1 : 0,
                                'active_duration': values.Activeduration
                                // "is_schedule_for_later": 1,
                                // "schedule_date": "30-11-24"
                            }

                            for (let index = 0; index < values.invoice.length; index++) {
                                const element: any = values.invoice[index];
                                NewAarry.push(element?.id)
                            }
                            if (values.SendVia == "email") {
                                body.subject = values.subject
                                body.body_text = values.body_text
                            }
                            if (recurringdata.recurring_type == "weekly" && values.recurring == true) {
                                body.recurring_type = recurringdata.recurring_type
                                body.week_day = recurringdata.day
                                body.recurring_stop_date = recurringdata.StopDate
                            }
                            if (recurringdata.recurring_type == "monthly" && values.recurring == true) {
                                body.recurring_type = recurringdata.recurring_type
                                body.month_date = recurringdata.date
                                body.recurring_stop_date = recurringdata.StopDate
                            }
                            if (recurringdata.recurring_type == "yearly" && values.recurring == true) {
                                body.recurring_type = recurringdata.recurring_type
                                body.yearly_month = recurringdata.months
                                body.yearly_date = recurringdata.monthsdate
                                body.recurring_stop_date = recurringdata.StopDate
                            }

                            const Path = (data: any) => {
                                setSuccessRes(data)
                                // setLoader(false)
                            }
                            if (recurringdata.recurring_type == "weekly" && values.recurring == true && !!recurringdata.recurring_type && !!recurringdata.day && !!recurringdata.StopDate) {
                                dispatch(payablesFlowactions.PostPayment("/payment-request/create", body, Path, setLoader) as any);

                            } else if (recurringdata.recurring_type == "monthly" && values.recurring == true && !!recurringdata.recurring_type && !!recurringdata.date && !!recurringdata.StopDate) {
                                dispatch(payablesFlowactions.PostPayment("/payment-request/create", body, Path, setLoader) as any);

                            } else if (recurringdata.recurring_type == "yearly" && values.recurring == true && !!recurringdata.recurring_type && !!recurringdata.months && !!recurringdata.monthsdate && !!recurringdata.StopDate) {
                                dispatch(payablesFlowactions.PostPayment("/payment-request/create", body, Path, setLoader) as any);

                            } else if (values.recurring == false) {
                                dispatch(payablesFlowactions.PostPayment("/payment-request/create", body, Path, setLoader) as any);

                            }


                        }}
                    >
                        {(formik) => (
                            <>
                                <Request_Payment_create CustomerName={CustomerName} setCustomerName={setCustomerName} setSuccessRes={setSuccessRes} formik={formik} SuccessRes={SuccessRes} loader={loader} recurringdata={recurringdata} setRecurringdata={setRecurringdata} CostomersId={CostomersId} />
                            </>
                        )}
                    </Formik>
                </ThemeProvider>
            </div>
        </>
    )
}
export default CreateRequestPayment;