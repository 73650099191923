import { Box, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from './connectInvoice.module.scss';
import ConnectInvoiceTable from './connectInvoiceTable';
import { useEffect, useMemo, useState } from 'react';
import { dashboardActions } from '../../../../../../../_actions';
import { useDispatch, useSelector } from "react-redux";
import { selectAllData } from "../../../../../../../_reducers/dashboard.reducer";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageHeader from '../../../../PageHeader';
import { Button } from 'react-bootstrap';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import InvoiceConnectionDetails from './connectionDetails';
const InvoiceConnection = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [invoiceDetail, setInvoiceDetail] = useState([])
      const [selected, setSelected] = useState<any>([]);

  const [moniteInvocieId, setMoniteInvocieId] = useState([])
  const[PageNo,setPageNo]=useState('1')
  const data = useSelector((state) => selectAllData(state));
  const navigate = useNavigate();
  const theme = createTheme({
    status: {
      danger: "#025041",
    },
  });

  useEffect(() => {
    const id: any = searchParams.get('id');
    setMoniteInvocieId(id)
  }, [searchParams]);

console.log(moniteInvocieId,"moniteInvocieId");

  useEffect(() => {
    if (moniteInvocieId) {
      dispatch(dashboardActions.getInvoicedetels(moniteInvocieId) as any);
    }
  }, [moniteInvocieId])

  const memoizedInvoiceData = useMemo(() => data?.GET_INVOICE_BY_ID_SUCCESS, [data?.GET_INVOICE_BY_ID_SUCCESS]);
  useEffect(() => {
    if (memoizedInvoiceData !== undefined) {
      setInvoiceDetail(memoizedInvoiceData?.data);
    }
  }, [memoizedInvoiceData]);


  return (
    <>
      <ThemeProvider theme={theme}>
        {PageNo=="1"?
        <>
          <PageHeader
            title="Connect Invoice with Payments"
            extra={
              <>
                <div style={{ height: "46px" }}></div>
              </>
            }
          />
         
          <Box className={styles.ContenarTebal}>
          <Button
          className={styles.GoBACKBUTTONTYLE}
              onClick={() => {
                navigate("/invoice?id=" + moniteInvocieId, { replace: true, state: { id: moniteInvocieId } })

              }}
            >
              <KeyboardBackspaceIcon />
              Go Back
            </Button>
            <ConnectInvoiceTable setPageNo={setPageNo} selected={selected} setSelected={setSelected} invoiceDetail={invoiceDetail} />
          </Box>
        </>:

        <InvoiceConnectionDetails selected={selected} />}
      </ThemeProvider>
    </>
  )
}
export default InvoiceConnection;
const BackIcon = () => (
  <svg
    width="17"
    height="8"
    viewBox="0 0 17 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM1 4.5H17V3.5H1V4.5Z"
      fill="#1D1D1D"
    />
  </svg>
);