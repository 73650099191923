import { Box, Grid, Typography } from "@mui/material";
import styles from "./customers_details.module.scss";
import moment from "moment";

const Setp1 = ({ Detels, DataArraylog }: any) => {
  console.log(Detels, "DetelsDetels");

  return (
    <>
      <Grid container className={styles.setp_container}>
        <Grid item md={3.9} xs={12} className={styles.grid_fast}>
          <Typography className={styles.heding_box}>Invoice Issued</Typography>
          <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
            <Typography className={styles.typo_contean}>
              Total Invoices
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{ display: "flex", justifyContent: "end" }}>
              {Detels?.total_issued_invoices}
            </Typography>
          </Box>
          <Box className={styles.contean_box}>
            <Typography className={styles.typo_contean}>
              Total Amount
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{ display: "flex", justifyContent: "end" }}>
              {Detels?.currency}{" "}{Detels?.total_issued_amount}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3.9} xs={12} className={styles.grid_fast}>
          <Typography className={styles.heding_box}> Invoices Paid</Typography>
          <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
            <Typography className={styles.typo_contean}>
              Total Invoices
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{ display: "flex", justifyContent: "end" }}>
              {Detels?.total_paid_invoices}
            </Typography>
          </Box>
          <Box className={styles.contean_box}>
            <Typography className={styles.typo_contean}>
              Total Amount
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{ display: "flex", justifyContent: "end" }}>
              {/* {dataList?.paid?.currancy}  */}
              {Detels?.currency}{" "}{Detels?.total_paid_amount}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3.9} xs={12} className={styles.grid_fast}>
          <Typography className={styles.heding_box}>
            Invoices Overdue
          </Typography>
          <Box className={styles.contean_box} sx={{ paddingBottom: "10px" }}>
            <Typography className={styles.typo_contean}>
              Total Invoices
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{ display: "flex", justifyContent: "end" }}>
              {Detels?.total_overdue_invoices}
            </Typography>
          </Box>
          <Box className={styles.contean_box}>
            <Typography className={styles.typo_contean} >
              Total Amount
            </Typography>
            <Typography className={styles.typo_contean_sub} style={{ display: "flex", justifyContent: "end" }}>
              {/* {dataList?.overdue?.currancy} */}
              {Detels?.currency}{" "}{Detels?.total_overdue_amount}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box className={styles.box_secend_poshan}>
            <Box className={styles.LineStart}></Box>
            <Typography className={styles.Recent_typo}>
              Recent activity
            </Typography>
            <Box className={styles.box_last_line}></Box>
          </Box>
        </Grid>

        <Grid container className={styles.setp_footer_container}>
          <Grid item md={2} sm={3} xs={3}>
            <Typography className={`${styles.name_text_HEDEAR}`}>
              Date
            </Typography>
          </Grid>
          <Grid item md={2} sm={3} xs={3}>
            <Typography className={`${styles.name_text_HEDEAR}`}>
              Type
            </Typography>
          </Grid>
          <Grid item md={4} sm={1} xs={1}></Grid>
          <Grid item md={4} sm={5} xs={5} >
            <Typography className={`${styles.name_text_HEDEAR}`}>
              Detail
            </Typography>
          </Grid>

          {DataArraylog.map((item: any, index: any) => {
            console.log(item, "itemitem123");

            return (
              <>
                {!!item?.log?.created_at ? <>
                  <Grid item md={2} sm={3} xs={3}>
                    <Typography className={styles.name_text}>
                      {moment(item?.log?.created_at).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={3} xs={3}>
                    <Typography className={styles.name_text}>
                      Invoice Created
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={1} xs={1}></Grid>
                  <Grid item md={4} sm={5} xs={5} >
                    <Typography className={styles.name_text}>
                      Invoice no. {item?.invoice_no} of amount {item?.currency}{item?.total} Created
                    </Typography>
                  </Grid>

                </> : ""}

                {!!item?.log?.updated_at ? <>
                  <Grid item md={2} sm={3} xs={3}>
                    <Typography className={styles.name_text}>
                      {moment(item?.log?.updated_at).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={3} xs={3}>
                    <Typography className={styles.name_text}>
                      Invoice Updated
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={1} xs={1}></Grid>
                  <Grid item md={4} sm={5} xs={5}>
                    <Typography className={styles.name_text}>
                      Invoice no. {item?.invoice_no} of amount {item?.currency}{' '}{item?.total} Updated
                    </Typography>
                  </Grid>

                </> : ""}
                {!!item?.log?.issued_at ? <>
                  <Grid item md={2} sm={3} xs={3}>
                    <Typography className={styles.name_text}>
                      {moment(item?.log?.issued_at).format("DD-MMM-YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item md={2} sm={3} xs={3}>
                    <Typography className={styles.name_text}>
                      Invoice Issued
                    </Typography>
                  </Grid>
                  <Grid item md={4} sm={1} xs={1}></Grid>
                  <Grid item md={4} sm={5} xs={5}>
                    <Typography className={styles.name_text}>
                      Invoice no. {item?.invoice_no} of amount {item?.currency}{' '}{item?.total} Issued
                    </Typography>
                  </Grid>

                </> : ""}
                {!!item?.log?.cancelled_at ?
                  <>
                    <Grid item md={2} sm={3} xs={3}>
                      <Typography className={styles.name_text}>
                        {moment(item?.log?.cancelled_at).format("DD-MMM-YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={3}>
                      <Typography className={styles.name_text}>
                        Invoice Cancellated
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={1} xs={1}></Grid>
                    <Grid item md={4} sm={5} xs={5}>
                      <Typography className={styles.name_text}>
                        Invoice no. {item?.invoice_no} of amount {item?.currency}{' '}{item?.total} Cancellated
                      </Typography>
                    </Grid>

                  </> : ""}
                {!!item?.log?.paid_at ?
                  <>
                    <Grid item md={2} sm={3} xs={3}>
                      <Typography className={styles.name_text}>
                        {moment(item?.log?.paid_at).format("DD-MMM-YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={3}>
                      <Typography className={styles.name_text}>
                        Invoice  Paid
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={1} xs={1}></Grid>
                    <Grid item md={4} sm={5} xs={5}>
                      <Typography className={styles.name_text}>
                        Invoice no. {item?.invoice_no} of amount {item?.currency}{" "}{Number(item?.paid_amount_after_partially_paid == null ? 0 : item?.paid_amount_after_partially_paid)} Paid
                      </Typography>
                    </Grid>

                  </> : ""}
                {!!item?.log?.partially_paid_at ?
                  <>
                    <Grid item md={2} sm={3} xs={3}>
                      <Typography className={styles.name_text}>
                        {moment(item?.log?.partially_paid_at).format("DD-MMM-YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={3}>
                      <Typography className={styles.name_text}>
                        Invoice  Partially Paid
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={1} xs={1}></Grid>
                    <Grid item md={4} sm={5} xs={5}>
                      <Typography className={styles.name_text}>
                        Invoice no. {item?.invoice_no} of amount {item?.currency}{" "}{Number(item?.partially_paid_amount == null ? 0 : item?.partially_paid_amount)} Partially Paid
                      </Typography>
                    </Grid>

                  </> : ""}
                {!!item?.log?.deleted_at ?
                  <>
                    <Grid item md={2} sm={3} xs={3}>
                      <Typography className={styles.name_text}>
                        {moment(item?.log?.deleted_at).format("DD-MMM-YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={3}>
                      <Typography className={styles.name_text}>
                        Invoice  Deleted
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={1} xs={1}></Grid>
                    <Grid item md={4} sm={5} xs={5}>
                      <Typography className={styles.name_text}>
                        Invoice no. {item?.invoice_no} of amount {item?.currency}{' '}{item?.total}  Deleted
                      </Typography>
                    </Grid>

                  </> : ""}
              </>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};
export default Setp1;
