import { CreateLayout } from '../../../../../ui/CreateLayout';
import { AutocompleteBox, SelectBox } from '../../../../../ui/selectBox';
import { MainHeding } from '../../../../../ui/Text';
import styles from './Request_Payment_create.module.scss';
import { Form } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions';
import { selectAllCounterpartsListData } from '../../../../../../_reducers/counterparts.reducer';
import { Box } from '@mui/material';
import { InputNumber } from '../../../../../ui/input';
import { payablesFlowactions } from '../../../../../../_actions';
import { ErrorMessage } from '../../../../../ui/eroor';
import { EmailTep } from '../../../../../ui/emailtep';
import { version } from 'xlsx';
import { AddButton, CreateButton, GoBackButton } from '../../../../../ui/Button';
import { Recurringcom } from '../../../../../ui/Recurring_com';
import { InputLayout } from '../../../../../ui/layoutInput/inputLayout';
import LinkCopyFile from '../../../../../ui/paymentlinkCopy';
import { getUser } from '../../../../../../_helpers';

const Request_Payment_create = ({ CostomersId, setRecurringdata, setSuccessRes, recurringdata, loader, SuccessRes, formik, setCustomerName, CustomerName }: any) => {
    const [render, setRender] = useState(true)
    const [ListdoupDoun, setListdoupDoun] = useState([])
    const [invoiceList, setInvoiceList] = useState([])
    const [currency, setCurrency] = useState([])
    const [submit, setSubmit] = useState(false)
    const [Activeduration, setActiveduration] = useState([
        {
            name: "24 hours",
            id: "24 hours"
        }, {
            name: "7 days",
            id: "7 days"
        }, {
            name: "30 days",
            id: "30 days"
        }, {
            name: "Until Paid",
            id: "until Paid"
        }
    ])

    const [SendVia, setSendvia] = useState([{
        name: "Whatsapp",
        id: "whatsapp"
    },
    {
        name: "Email",
        id: "email"
    }])
    const formdata = useRef();
    const dispatch = useDispatch()
    const user = getUser()?.data ?? null;
    console.log(CostomersId, "CostomersId");

    const counterpartsListData = useSelector((state) =>
        selectAllCounterpartsListData(state)
    );
    console.log(SuccessRes, submit, "SuccessResSuccessRes");

    useEffect(() => {
        if (!!CostomersId) {
            const costomers: any = ListdoupDoun.find((item: any) => item.id == CostomersId)
            console.log(costomers, "costomerscostomers");

            formik.setFieldValue(
                `CustomerName`,
                costomers?.id
            );
            formik.setFieldValue(
                `body_text`,
                `Hi ${costomers?.name},\nPlease find the invoice attached as discussed.\nKind regards,\n${!!user ? (!!user?.name ? user?.name : '') : ''}`
            );
        }
    }, [CostomersId, ListdoupDoun])

    const Listapicoutemar = (data: any) => {
        setListdoupDoun(data)

    }
    const ListapiInvoice = (data: any) => {
        setInvoiceList(data)
    }
    const CurrancyList = (data: any) => {
        // setCurrency(data)
        var NewArray:any = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index];

            if (element.currency == "GHS") {

            }
            else {
                NewArray.push(element)
            }
        }
        setCurrency(NewArray)
    }
    useEffect(() => {
        if (render) {

            dispatch(payablesFlowactions.getApipayment('/payment-request/get-customers', Listapicoutemar) as any);
            dispatch(payablesFlowactions.getApipayment("/payment-link-api/get-currencies", CurrancyList) as any);
        }
    }, [render])

    // useEffect(() => {
    //     if (counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS !== undefined && listRender) {
    //         setListRender(false)
    //         setListdoupDoun(counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS)
    //     }
    // }, [listRender, counterpartsListData])


    const PoupClose = () => {
        setSuccessRes('')
    }
    return (
        <>
            {!SuccessRes ?
                <>
                    <CreateLayout Path='/Request_Payment'>



                        {/* {(formik) => ( */}
                        <>

                            {console.log(formik.values, formik.errors, CostomersId, 'values')}
                            <Form onSubmit={formik.handleSubmit} >
                                <MainHeding Text={'Request payment'} ></MainHeding>
                                <InputLayout style={{ paddingTop: '25px' }}>
                                    <SelectBox disabled={!!CostomersId ? true : false} mainDevstyle={{ margin: '0px' }} label={'Customer'} list={ListdoupDoun} value={formik.values.CustomerName} name="CustomerName" onChangeset={(e: any) => {
                                        formik.setFieldValue(
                                            `CustomerName`,
                                            e.target.value
                                        );
                                        const findName: any = ListdoupDoun.find((item: any) => item.id == e.target.value)
                                        console.log(findName, "findNamefindName");
                                        dispatch(payablesFlowactions.getApipayment(`/payment-request/get-invoices?currency=${formik.values.Currency}&customer_id=${e.target.value}`, ListapiInvoice) as any);
                                        formik.setFieldValue(
                                            `invoice`,
                                            []
                                        );
                                        formik.setFieldValue(
                                            `body_text`,
                                            `Hi ${findName?.name},\nPlease find the invoice attached as discussed.\nKind regards,\n${!!user ? (!!user?.name ? user?.name : '') : ''}`
                                        );
                                        //
                                        setCustomerName(findName?.name)
                                    }} />
                                    {formik.touched.CustomerName && formik.errors.CustomerName ? (
                                        <ErrorMessage >{formik.errors.CustomerName}</ErrorMessage>
                                    ) : null}
                                </InputLayout>
                                <InputLayout >
                                    <SelectBox mainDevstyle={{ margin: '0px' }} label={'Currency'} list={currency} currency={'currency'} value={formik.values.Currency} name="Currency"
                                        onChangeset={(e: any) => {
                                            formik.setFieldValue(
                                                `Currency`,
                                                e.target.value
                                            );
                                            // handleChange(e.target.value)
                                            dispatch(payablesFlowactions.getApipayment(`/payment-request/get-invoices?currency=${e.target.value}&customer_id=${formik.values.CustomerName}`, ListapiInvoice) as any);
                                            formik.setFieldValue(
                                                `invoice`,
                                                []
                                            );
                                            formik.setFieldValue(
                                                `Amount`,
                                                0
                                            );
                                        }}

                                    />
                                    {formik.touched.Currency && formik.errors.Currency ? (
                                        <ErrorMessage>{formik.errors.Currency}</ErrorMessage>
                                    ) : null}
                                </InputLayout>
                                <Box >
                                    <AutocompleteBox disabled={!!formik.values.Currency&&!!formik.values.CustomerName?false:true}  list={invoiceList} label={'Select invoice'} name="invoice" value={formik.values.invoice} onChangeset={(data: any) => {
                                        formik.setFieldValue(
                                            `invoice`,
                                            data
                                        );
                                        let amount = 0;
                                        for (let index = 0; index < data.length; index++) {
                                            const element = data[index];
                                            amount += Number(element.due_amount)
                                        }
                                        formik.setFieldValue(
                                            `Amount`,
                                            amount
                                        );
                                    }} />
                                    {formik.touched.invoice && formik.errors.invoice ? (
                                        <ErrorMessage>{formik.errors.invoice}</ErrorMessage>
                                    ) : null}
                                </Box>
                                {/* <Box className={styles.classBoxmain}> */}
                                <InputLayout >
                                    <InputNumber mainDevstyle={{ margin: '0px' }} disabled={formik.values.invoice.length == 0 ? false : true} value={formik.values.Amount} name="Amount" onChangeset={formik.handleChange} label={'Amount'} />
                                    {formik.touched.Amount && formik.errors.Amount ? (
                                        <ErrorMessage>{formik.errors.Amount}</ErrorMessage>
                                    ) : null}
                                </InputLayout>

                                {/* </Box> */}
                                <InputLayout>
                                    <SelectBox mainDevstyle={{ margin: '0px' }} label={'Active duration'} list={Activeduration} value={formik.values.Activeduration} name="Activeduration" onChangeset={formik.handleChange} />
                                    {formik.touched.Activeduration && formik.errors.Activeduration ? (
                                        <ErrorMessage>{formik.errors.Activeduration}</ErrorMessage>
                                    ) : null}
                                </InputLayout>
                                <InputLayout>
                                    <SelectBox mainDevstyle={{ margin: '0px' }} label={'Send Via'} list={SendVia} value={formik.values.SendVia} name="SendVia" onChangeset={(e: any) => {
                                        formik.setFieldValue('SendVia', e.target.value)
                                        formik.setFieldValue('body_text', `Hi ${CustomerName},\nPlease find the invoice attached as discussed.\nKind regards,\n${!!user ? (!!user?.name ? user?.name : '') : ''}`)
                                    }} />
                                    {formik.touched.SendVia && formik.errors.SendVia ? (
                                        <ErrorMessage>{formik.errors.SendVia}</ErrorMessage>
                                    ) : null}
                                </InputLayout>
                                {formik.values.SendVia == "email" ?
                                    <Box>
                                        <EmailTep
                                            onChangeBodyset={(data: any) => {
                                                formik.setFieldValue(
                                                    `body_text`,
                                                    data
                                                );
                                            }}
                                            body_text={formik.values.body_text}
                                            subjectvelue={formik.values.subject}
                                            onChangesubjectset={(data: any) => {
                                                formik.setFieldValue(
                                                    `subject`,
                                                    data
                                                );

                                            }} />
                                    </Box> : ""}
                                <Box>
                                    <AddButton onclickset={() => {
                                        if (formik.values.recurring == false) {
                                            formik.setFieldValue(
                                                `recurring`,
                                                true
                                            );
                                        } else {
                                            formik.setFieldValue(
                                                `recurring`,
                                                false
                                            );
                                        }
                                    }}>+Recurring</AddButton>
                                </Box>
                                {formik.values.recurring == true ? <Box>
                                    <Recurringcom submit={submit} setRecurringdata={setRecurringdata} values={recurringdata} />
                                </Box> : ""}
                                <Box sx={{ paddingTop: "28px" }}>
                                    <CreateButton loader={loader} type={'submit'} setonClick={() => { setSubmit(true) }} style={{ width: "100%" }}>
                                        Request payment
                                    </CreateButton>
                                </Box>
                            </Form>

                        </>
                        {/* )} */}


                    </CreateLayout >
                </>
                :
                <LinkCopyFile data={SuccessRes} goBackPath='/Request_Payment' editPath={`/Create-RequestPayment?id${SuccessRes?.id}`} onClose={PoupClose} />}
        </>
    )
}
export default Request_Payment_create;