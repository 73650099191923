import PageHeader from '../PageHeader';
import { Dropdown, DropdownMenuItem } from '@team-monite/ui-kit-react';
import './CereteCounterparts.scss';
import { Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from 'react-country-region-selector';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PhoneInput from 'react-phone-input-2';
import { Formik, Field, ErrorMessage } from 'formik';
import { paymentJourneyActions } from '../../../../_actions/paymentJourney.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProperties,
  getUser,
  isUserAuthenticated,
} from '../../../../_helpers';
import React from 'react';
import { selectPaymentJourneyData } from '../../../../_reducers/paymentJourney.reducer';
import { counterpartsActions } from '../../../../_actions/counterparts.actions';
import { useSearchParams } from 'react-router-dom';
import { selectAllCounterpartsListData } from '../../../../_reducers/counterparts.reducer';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Yup from 'yup';
import posthog from 'posthog-js';
import amplitude from 'amplitude-js';
import { useNavigate } from 'react-router-dom';

export const CereteCounterparts = () => {
  const [fild_data, setFild_data] = useState({
    First_name: '',
    Last_name: '',
    Type: 'individual',
    Select_fild: false,
    Phone_number: '',
    Email: '',
    Street_address: '',
    Country: 'KE',
    typePayment: 'M-Pesa',
    mpesa_type: '',
    Send_Money: '',
    paybill_number: '',
    account_number: '',
    till_number: '',
    tax_identification_number: '',
  });
  const [bank_data, setBank_data] = useState({
    bank_name: '',
    branch_name: '',
    account_name: '',
    account_number: '',
  });
  const properties = getProperties();
  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);
  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
  const [submitData, setSubmitdata] = useState(false);
  const [render, setRender] = useState(true);
  const [render_two, setRender_two] = useState(true);
  const [bankListRender, setBankListRender] = useState<any>(true);
  const [bankListRender_two, setBankListRender_two] = useState<any>(true);
  const [data_submit, setDataSubmit] = useState(false);
  const [bankList, setBankList] = useState<any>([]);
  const dispatch = useDispatch();
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const counterpartData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [datadisplay, setDatadisplay] = useState('');
  const Navigate = useNavigate();

  React.useEffect(() => {
    const popup = searchParams.get('id');
    if (!!popup) {
      setDatadisplay(popup);;
    } else {
      setDatadisplay('');
    }
  }, [searchParams]);
  React.useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(paymentJourneyActions.getPesaBankList() as any);
    }
  }, [render, isUserAuthenticated]);
  React.useEffect(() => {
    if (isUserAuthenticated() && render_two && !!datadisplay) {
      dispatch(counterpartsActions.geteditCounterparts(datadisplay) as any);
      setRender_two(false);
    }
  }, [render_two, isUserAuthenticated, datadisplay]);
  React.useEffect(() => {
    if (
      bankListRender_two &&
      counterpartData?.GETUSERCOUNTERPART_EDIT_SUCCESS !== undefined &&
      !!datadisplay
    ) {
      setBankListRender_two(false);
      console.log(
        counterpartData?.GETUSERCOUNTERPART_EDIT_SUCCESS,
        'counterpartData?.GETUSERCOUNTERPART_EDIT_SUCCESS'
      );
      const datalist = counterpartData?.GETUSERCOUNTERPART_EDIT_SUCCESS;
      setFild_data({
        First_name:
          datalist.counterpart_type == 'individual'
            ? datalist.contact_first_name
            : datalist.name,
        Last_name: datalist.contact_last_name,
        Type: datalist.counterpart_type,
        Select_fild: datalist.is_customer,
        Phone_number: datalist.contact_phone,
        Email: datalist.contact_email,
        Street_address: datalist.contact_line1,
        Country: datalist.contact_country,
        tax_identification_number: datalist.tax_identification_number,
        typePayment: 'M-Pesa',
        mpesa_type: '',
        Send_Money: !!datalist.account ? datalist.account.phone_number : '',
        paybill_number: !!datalist.account
          ? datalist.account.paybill_number
          : '',
        account_number: !!datalist.account
          ? datalist.account.account_number
          : '',
        till_number: !!datalist.account ? datalist.account.till_number : '',
      });
      setBank_data({
        bank_name: !!datalist.account ? datalist.account.bank_name : '',
        branch_name: !!datalist.account ? datalist.account.bank_branch : '',
        account_name: !!datalist.account ? datalist.account.account_name : '',
        account_number: !!datalist.account
          ? datalist.account.bank_account_number
          : '',
      });
    }
  }, [counterpartData, bankListRender]);
  React.useEffect(() => {
    if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
      setBankListRender(false);
      setBankList(dataList.GETPESABANKLIST_SUCCESS);
    }
  }, [dataList, bankListRender]);
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  console.log(fild_data.Select_fild , "yango");
  const haredData = () => {
    setSubmitdata(true);

    if (
      !!fild_data.First_name &&
      !!fild_data.Last_name &&
      fild_data.Type == 'individual'
    ) {
      var findresults: any = '';
      if (!!bank_data.bank_name) {
        findresults = bankList.find(
          (entry: any) => entry.bank_name === bank_data.bank_name
        );
      }

      var body = {
        type: fild_data.Type,
        first_name: fild_data.First_name,
        last_name: fild_data.Last_name,

        phone: !!fild_data.Phone_number
          ? fild_data.Phone_number
          : '254123456789',
        email: !!fild_data.Email ? fild_data.Email : 'hello@niobi.co',
        country: !!fild_data.Country ? fild_data.Country : 'KE',
        city: 'city',
        postal_code: 'postal_code',
        tax_identification_number: fild_data.tax_identification_number,
        line1: !!fild_data.Street_address
          ? fild_data.Street_address
          : '123 Main Street',
        is_customer: fild_data.Select_fild == true ? 1 : 0,
        payment_details: {
          phone_number: fild_data.Send_Money,
          paybill_number: fild_data.paybill_number,
          account_number: fild_data.account_number,
          till_number: fild_data.till_number,
          bank_name: bank_data.bank_name,
          bank_branch: 'bank_branch',
          bank_account_number: bank_data.account_number,
          account_name: bank_data.account_name,
          bank_code: !!findresults ? findresults.niobi_bank_code : '',
        },
      };

      setDataSubmit(true);
      if (!!datadisplay) {
        dispatch(
          counterpartsActions.edit_Counterparts(body, datadisplay) as any
        );

        // add update payee
      } else {
        dispatch(counterpartsActions.CeretePaybles(body) as any);
        const event = 'Payee Created';
        const eventProperties = {
          userId:
            !!properties && !!properties.user_id ? properties.user_id : null,
          userName: !!properties && !!properties.name ? properties.name : null,
          email: !!properties && !!properties.email ? properties.email : null,
          pageUrl: window.location.href,
        };
        amplitude.getInstance().logEvent(event, eventProperties);
        posthog.capture(event, eventProperties);
      }
    } else if (!!fild_data.First_name && fild_data.Type == 'organization') {
      var findresults: any = '';
      if (!!bank_data.bank_name) {
        findresults = bankList.find(
          (entry: any) => entry.bank_name === bank_data.bank_name
        );
      }
      var body_two: any = {
        type: fild_data.Type,
        legal_name: fild_data.First_name,
        "phone": !!fild_data.Phone_number ? fild_data.Phone_number : "254123456789",
        "email": !!fild_data.Email ? fild_data.Email : "hello@niobi.co",
        "country": !!fild_data.Country ? fild_data.Country : "KE",
        "city": 'city',
        "tax_identification_number": fild_data.tax_identification_number,
        "postal_code": 'postal_code',
        "line1": !!fild_data.Street_address ? fild_data.Street_address : "123 Main Street",
        'is_customer': fild_data.Select_fild == true ? 1 : 0,
        "payment_details": {
          "phone_number": fild_data.Send_Money,
          "paybill_number": fild_data.paybill_number,
          "account_number": fild_data.account_number,
          "till_number": fild_data.till_number,
          "bank_name": bank_data.bank_name,
          "bank_branch": "bank_branch",
          "bank_account_number": bank_data.account_number,
          "account_name": bank_data.account_name,
          "bank_code": !!findresults ? findresults.niobi_bank_code : ""
        }
      }
      setDataSubmit(true)
      if (!!datadisplay) {
        dispatch(counterpartsActions.edit_Counterparts(body_two, datadisplay) as any)
      } else {
        dispatch(counterpartsActions.CeretePaybles(body_two) as any)
      }
    }

  }
  return (
    <>
     <PageHeader
            title="Payees"
            extra={
              <div style={{height:"44px"}}></div>
            }
            />
      {/* <div className="balance__header">
        <div className="balance__header__name">Payees</div>
        <div style={{height:"44px"}}></div>
        <div>
        </div>
      </div> */}
      <div  className='main_contenar_listts'>
        <Button id="button_back" style={{ color: '#025141',marginLeft:"30px" }}
          onClick={() => {
            // window.location.href = '/Counterparts'
            Navigate('/Counterparts')

          }} className="payment-journey-container__detailbox__white-btn">
          <ThemeProvider theme={theme}>
            <ArrowBackIcon style={{ fontSize: "19px", marginRight: "10px" }} />
          </ThemeProvider>
          Go Back
        </Button>  <div className='box_contean_data_contenar'>
          <p className="p_tage_cerete_data">
            {!!datadisplay ? "Update Payee" : "Create a Payee"}
          </p>
          <div className='main_fast_and_last' style={{marginBottom:"-20px"}}>
            <div style={{width: fild_data.Type == "organization" ? "100%" : "49%"}}>
              <div className="input-form-wrapper dot">
                <label>{fild_data.Type == "individual" ? "First name" : "Organization name"}</label>
                <InputGroup style={{ fontSize: '12px' }} className="doc-input-wrapper">
                  <Form.Control
                    className={
                      'doc-form-input test valid'}
                    
                    placeholder={fild_data.Type == "individual" ? "First name" : "Organization name"}
                    value={fild_data.First_name}
                    name='name'
                    type="text"
                    onChange={(e: any) => {
                      setFild_data({
                        First_name: e.target.value,
                        Last_name: fild_data.Last_name,
                        Type: fild_data.Type,
                        Select_fild: fild_data.Select_fild,
                        Street_address: fild_data.Street_address,
                        Email: fild_data.Email,
                        Phone_number: fild_data.Phone_number,
                        Country: fild_data.Country,

                        typePayment: fild_data.typePayment,
                        mpesa_type: fild_data.mpesa_type,
                        Send_Money: fild_data.Send_Money,
                        paybill_number: fild_data.paybill_number,
                        account_number: fild_data.account_number,
                        till_number: fild_data.till_number,
                        tax_identification_number: fild_data.tax_identification_number
                      });
                    }}
                  />
                </InputGroup>
                {!fild_data.First_name && submitData == true ? (
                  <p className="error-message">
                    Please enter the{' '}
                    {fild_data.Type == 'individual'
                      ? 'first name'
                      : 'organization name'}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div style={{width: fild_data.Type == "organization" ? "0%" : "49%"}}>
              {fild_data.Type == 'organization' ? (
                ''
              ) : (
                <div className="input-form-wrapper dot">
                  <label>Last name</label>
                  <InputGroup className="doc-input-wrapper">
                    <Form.Control
                      disabled={fild_data.Type == 'individual' ? false : true}
                      className={'doc-form-input test valid'}
                      placeholder="Last name"
                      value={fild_data.Last_name}
                      name="name"
                      type="text"
                      onChange={(e: any) => {
                        setFild_data({
                          First_name: fild_data.First_name,
                          Last_name: e.target.value,
                          Type: fild_data.Type,
                          Select_fild: fild_data.Select_fild,
                          Street_address: fild_data.Street_address,
                          Email: fild_data.Email,
                          Phone_number: fild_data.Phone_number,
                          Country: fild_data.Country,

                          typePayment: fild_data.typePayment,
                          mpesa_type: fild_data.mpesa_type,
                          Send_Money: fild_data.Send_Money,
                          paybill_number: fild_data.paybill_number,
                          account_number: fild_data.account_number,
                          till_number: fild_data.till_number,
                          tax_identification_number: fild_data.tax_identification_number
                        });
                      }}
                    />
                  </InputGroup>
                  {!fild_data.Last_name &&
                    fild_data.Type !== 'organization' &&
                    submitData == true ? (
                    <p className="error-message">Please enter the last name</p>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="input-form-wrapper">
            <label>Tax Identification Number</label>
            <InputGroup className='doc-input-wrapper'>
              <Form.Control
                type="text"
                name="name"
                className="doc-form-input test valid"
                placeholder="Enter Tax Identification Number"
                value={fild_data.tax_identification_number}
                onChange={(e) =>
                  setFild_data({

                    First_name: fild_data.First_name,
                    Last_name: fild_data.Last_name,
                    Type: fild_data.Type,
                    Select_fild: fild_data.Select_fild,
                    Email: fild_data.Email,
                    Phone_number: fild_data.Phone_number,
                    Street_address: fild_data.Street_address,
                    Country: fild_data.Country,

                    typePayment: fild_data.typePayment,
                    mpesa_type: fild_data.mpesa_type,
                    Send_Money: fild_data.Send_Money,
                    paybill_number: fild_data.paybill_number,
                    account_number: fild_data.account_number,
                    till_number: fild_data.till_number,
                    tax_identification_number: e.target.value
                  })
                }

              />
            </InputGroup>

          </div>
          <div>
            <ThemeProvider theme={theme}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                className="maib_redio_button_contenar"
                value={fild_data.Type}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFild_data({
                    First_name: fild_data.First_name,
                    Last_name: fild_data.Last_name,
                    Type: (event.target as HTMLInputElement).value,
                    Select_fild: fild_data.Select_fild,
                    Street_address: fild_data.Street_address,
                    Email: fild_data.Email,
                    Phone_number: fild_data.Phone_number,
                    Country: fild_data.Country,

                    typePayment: fild_data.typePayment,
                    mpesa_type: fild_data.mpesa_type,
                    Send_Money: fild_data.Send_Money,
                    paybill_number: fild_data.paybill_number,
                    account_number: fild_data.account_number,
                    till_number: fild_data.till_number,
                    tax_identification_number: fild_data.tax_identification_number
                  });
                }}
              >
                <FormControlLabel
                  className="main_redio_buttton"
                  value="individual"
                  control={
                    <Radio
                      disabled={!!datadisplay ? true : false}
                      icon={<img src="/Checkbox.svg" />}
                      checkedIcon={<img src="/Checkbox_two.svg" />}
                    />
                  }
                  label="Individual"
                />
                <FormControlLabel
                  className="main_redio_buttton"
                  value="organization"
                  control={
                    <Radio
                      disabled={!!datadisplay ? true : false}
                      icon={<img src="/Checkbox.svg" />}
                      checkedIcon={<img src="/Checkbox_two.svg" />}
                    />
                  }
                  label="Organization"
                />
              </RadioGroup>
            </ThemeProvider>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <div
                style={{ paddingBottom: '10px', paddingTop: '10px' }}
                className="amont_text_p_chackbox"
              >
                <Checkbox
                 
                  style={{ padding: '0px 10px 0px 0px' }}
                  icon={<img src="/Checkbox.svg" />}
                  checkedIcon={<img src="/Checkbox_two.svg" />}
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFild_data({
                      First_name: fild_data.First_name,
                      Last_name: fild_data.Last_name,
                      Type: fild_data.Type,
                      Select_fild: event.target.checked,
                      Street_address: fild_data.Street_address,
                      Email: fild_data.Email,
                      Phone_number: fild_data.Phone_number,
                      Country: fild_data.Country,
                      typePayment: fild_data.typePayment,
                      mpesa_type: fild_data.mpesa_type,
                      Send_Money: fild_data.Send_Money,
                      paybill_number: fild_data.paybill_number,
                      account_number: fild_data.account_number,
                      till_number: fild_data.till_number,
                      tax_identification_number: fild_data.tax_identification_number
                    });
                  }}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
                <p className="classname_customer">
                  Set this payee as a customer
                </p>
              </div>
            </ThemeProvider>
          </div>
          <div className="calss_accordion_contenar">
            <ThemeProvider theme={theme}>
              <div
                className="maian_adaat_listtts"
                onClick={() => {
                  if (dataaccodiyan == false) {
                    setDataccodiyan(true);
                    setPaymentacodiyan(false);
                  } else {
                    setDataccodiyan(false);
                  }
                }}
              >
                <p className="accordion_p_data_contean">Contact details</p>
                {dataaccodiyan == false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </div>
              {dataaccodiyan == true ? (
                <div className="accordion_details_contean">
                  <div className="input-form-wrapper">
                    <label>Email</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={'doc-form-input test valid'}
                        placeholder="hello@niobi.co"
                        value={fild_data.Email}
                        name="name"
                        type="email"
                        onChange={(e: any) => {
                          setFild_data({
                            First_name: fild_data.First_name,
                            Last_name: fild_data.Last_name,
                            Type: fild_data.Type,
                            Select_fild: fild_data.Select_fild,
                            Email: e.target.value,
                            Street_address: fild_data.Street_address,
                            Phone_number: fild_data.Phone_number,
                            Country: fild_data.Country,

                            typePayment: fild_data.typePayment,
                            mpesa_type: fild_data.mpesa_type,
                            Send_Money: fild_data.Send_Money,
                            paybill_number: fild_data.paybill_number,
                            account_number: fild_data.account_number,
                            till_number: fild_data.till_number,
                            tax_identification_number: fild_data.tax_identification_number
                          });
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="input-form-wrapper">
                    <label>Phone number</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={'doc-form-input test valid'}
                        placeholder="254123456789"
                        value={fild_data.Phone_number}
                        name="name"
                        type="text"
                        onChange={(e: any) => {
                          setFild_data({
                            First_name: fild_data.First_name,
                            Last_name: fild_data.Last_name,
                            Type: fild_data.Type,
                            Select_fild: fild_data.Select_fild,
                            Email: fild_data.Email,
                            Phone_number: e.target.value,
                            Street_address: fild_data.Street_address,
                            Country: fild_data.Country,

                            typePayment: fild_data.typePayment,
                            mpesa_type: fild_data.mpesa_type,
                            Send_Money: fild_data.Send_Money,
                            paybill_number: fild_data.paybill_number,
                            account_number: fild_data.account_number,
                            till_number: fild_data.till_number,
                            tax_identification_number: fild_data.tax_identification_number
                          });
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="input-form-wrapper">
                    <label>Street address</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={'doc-form-input test valid'}
                        placeholder="123 Main Street"
                        value={fild_data.Street_address}
                        name="name"
                        type="text"
                        onChange={(e: any) => {
                          setFild_data({
                            First_name: fild_data.First_name,
                            Last_name: fild_data.Last_name,
                            Type: fild_data.Type,
                            Select_fild: fild_data.Select_fild,
                            Email: fild_data.Email,
                            Phone_number: fild_data.Phone_number,
                            Street_address: e.target.value,
                            Country: fild_data.Country,

                            typePayment: fild_data.typePayment,
                            mpesa_type: fild_data.mpesa_type,
                            Send_Money: fild_data.Send_Money,
                            paybill_number: fild_data.paybill_number,
                            account_number: fild_data.account_number,
                            till_number: fild_data.till_number,
                            tax_identification_number: fild_data.tax_identification_number
                          });
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="input-form-wrapper">
                    <label>Country</label>
                    <CountryDropdown
                      value={fild_data.Country}
                      valueType="short"
                      onChange={(e: any, val) => {
                        console.log(e, val, ' e.target.value e.target.value');

                        setFild_data({
                          First_name: fild_data.First_name,
                          Last_name: fild_data.Last_name,
                          Type: fild_data.Type,
                          Select_fild: fild_data.Select_fild,
                          Email: fild_data.Email,
                          Phone_number: fild_data.Phone_number,
                          Street_address: fild_data.Street_address,
                          Country: e,

                          typePayment: fild_data.typePayment,
                          mpesa_type: fild_data.mpesa_type,
                          Send_Money: fild_data.Send_Money,
                          paybill_number: fild_data.paybill_number,
                          account_number: fild_data.account_number,
                          till_number: fild_data.till_number,
                          tax_identification_number: fild_data.tax_identification_number
                        });
                      }}
                      name="country"
                      classes={'doc-form-input test valid'}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </ThemeProvider>
          </div>
          <div className="calss_accordion_contenar">
            <ThemeProvider theme={theme}>
              <div
                className="maian_adaat_listtts"
                onClick={() => {
                  if (paymentacodiyan == false) {
                    setPaymentacodiyan(true);
                    setDataccodiyan(false);
                  } else {
                    setPaymentacodiyan(false);
                  }
                }}
              >
                <p className="accordion_p_data_contean">Payment details</p>
                {paymentacodiyan == false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </div>
              {paymentacodiyan == true ? (
                <div className="accordion_details_contean">
                  <div className="mainana_cantena_daat_potrrr">
                    <Button
                      className={
                        fild_data.typePayment == 'M-Pesa'
                          ? 'Minn_button_mpesa_datata'
                          : 'calss_not_active_data'
                      }
                      onClick={() => {
                        setFild_data({
                          First_name: fild_data.First_name,
                          Last_name: fild_data.Last_name,
                          Type: fild_data.Type,
                          Select_fild: fild_data.Select_fild,
                          Email: fild_data.Email,
                          Phone_number: fild_data.Phone_number,
                          Street_address: fild_data.Street_address,
                          Country: fild_data.Country,

                          typePayment: 'M-Pesa',

                          mpesa_type: fild_data.mpesa_type,
                          Send_Money: fild_data.Send_Money,
                          paybill_number: fild_data.paybill_number,
                          account_number: fild_data.account_number,
                          till_number: fild_data.till_number,
                          tax_identification_number: fild_data.tax_identification_number
                        });
                      }}
                    >
                      M-Pesa
                    </Button>
                    <Button
                      className={
                        fild_data.typePayment == 'Bank transfer'
                          ? 'Minn_button_mpesa_datata'
                          : 'calss_not_active_data'
                      }
                      onClick={() => {
                        setFild_data({
                          First_name: fild_data.First_name,
                          Last_name: fild_data.Last_name,
                          Type: fild_data.Type,
                          Select_fild: fild_data.Select_fild,
                          Email: fild_data.Email,
                          Street_address: fild_data.Street_address,
                          Phone_number: fild_data.Phone_number,
                          Country: fild_data.Country,

                          typePayment: "Bank transfer",
                          mpesa_type: fild_data.mpesa_type,
                          Send_Money: fild_data.Send_Money,
                          paybill_number: fild_data.paybill_number,
                          account_number: fild_data.account_number,
                          till_number: fild_data.till_number,
                          tax_identification_number: fild_data.tax_identification_number
                        })
                      }}>
                      Bank Transfer
                    </Button>
                  </div>
                  {fild_data.typePayment == 'M-Pesa' ?
                    <>
                      <div style={{ padding: "10px 0px 0px 0px" }}>
                        <div style={{ marginBottom: "0px" }} className={`mpesa-container__option-wrapper mpaisa ${fild_data.mpesa_type === 'send money' ? 'border-green' : ""}`}
                          onClick={() => {
                            setFild_data({
                              First_name: fild_data.First_name,
                              Last_name: fild_data.Last_name,
                              Type: fild_data.Type,
                              Select_fild: fild_data.Select_fild,
                              Email: fild_data.Email,
                              Phone_number: fild_data.Phone_number,
                              Street_address: fild_data.Street_address,
                              Country: fild_data.Country,

                              typePayment: fild_data.typePayment,
                              mpesa_type: "send money",
                              Send_Money: fild_data.Send_Money,
                              paybill_number: fild_data.paybill_number,
                              account_number: fild_data.account_number,
                              till_number: fild_data.till_number,
                              tax_identification_number: fild_data.tax_identification_number
                            })
                          }}
                        >
                          <div className="accordion_p_data_contean">Send money</div>
                          {fild_data.mpesa_type === 'send money' ? <ExpandMoreIcon style={{ color: "#1D1D1D" }} /> : <ExpandLessIcon style={{ color: "#1D1D1D" }} />}
                        </div>
                        {fild_data.mpesa_type === 'send money' && (
                          <>
                            <PhoneInput

                              inputProps={{
                                name: 'phone_number',
                                required: true,
                                autoFocus: true
                              }}
                              country={'ke'}
                              containerStyle={{ margin: "10px 0px 5px 0px" }}
                              prefix=''
                              value={fild_data?.Send_Money}
                              onChange={(value: any, country: any, e: any, formattedValue: any) => {
                                if (parseInt(value).toString().length < 12) {
                                  <p style={{ padding: "0px" }} className="error-message">Please enter a valid phone number</p>
                                } else {
                                  setFild_data({
                                    First_name: fild_data.First_name,
                                    Last_name: fild_data.Last_name,
                                    Type: fild_data.Type,
                                    Select_fild: fild_data.Select_fild,
                                    Email: fild_data.Email,
                                    Phone_number: fild_data.Phone_number,
                                    Street_address: fild_data.Street_address,
                                    Country: fild_data.Country,

                                    typePayment: fild_data.typePayment,
                                    mpesa_type: fild_data.mpesa_type,
                                    Send_Money: value,
                                    paybill_number: fild_data.paybill_number,
                                    account_number: fild_data.account_number,
                                    till_number: fild_data.till_number,
                                    tax_identification_number: fild_data.tax_identification_number
                                  });
                                }
                              }}
                              inputClass={'inputBx-mobile'}
                              autoFormat={false}
                            />
                            {!!fild_data.Send_Money && submitData == true && parseInt(fild_data.Send_Money).toString().length !== 12 ? <p style={{ padding: "0px" }} className="error-message">Please enter a valid phone number</p> : ""}
                          </>

                        )}
                      </div>
                      <div style={{ padding: '10px 0px 0px 0px' }}>
                        <div
                          style={{ marginBottom: '0px' }}
                          className={`mpesa-container__option-wrapper mpaisa ${fild_data.mpesa_type === 'Paybill'
                              ? 'border-green'
                              : ''
                            }`}
                          onClick={() => {
                            setFild_data({
                              First_name: fild_data.First_name,
                              Last_name: fild_data.Last_name,
                              Type: fild_data.Type,
                              Select_fild: fild_data.Select_fild,
                              Email: fild_data.Email,
                              Phone_number: fild_data.Phone_number,
                              Street_address: fild_data.Street_address,
                              Country: fild_data.Country,

                              typePayment: fild_data.typePayment,
                              mpesa_type: 'Paybill',
                              Send_Money: fild_data.Send_Money,
                              paybill_number: fild_data.paybill_number,
                              account_number: fild_data.account_number,
                              till_number: fild_data.till_number,
                              tax_identification_number: fild_data.tax_identification_number
                            });
                          }}
                        >
                          <div className="accordion_p_data_contean">
                            Paybill
                          </div>
                          {fild_data.mpesa_type === 'Paybill' ? (
                            <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                          ) : (
                            <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                          )}
                        </div>
                        <div>
                          {fild_data.mpesa_type === 'Paybill' && (
                            <>
                              <div
                                style={{ margin: '10px 0px' }}
                                className="input-form-wrapper dot"
                              >
                                <InputGroup className="doc-input-wrapper">
                                  <Form.Control
                                    className={'doc-form-input test valid'}
                                    type="text"
                                    placeholder="Input paybill number here."
                                    value={fild_data?.paybill_number}
                                    onChange={(e: any) => {
                                      setFild_data({
                                        First_name: fild_data.First_name,
                                        Last_name: fild_data.Last_name,
                                        Type: fild_data.Type,
                                        Select_fild: fild_data.Select_fild,
                                        Email: fild_data.Email,
                                        Phone_number: fild_data.Phone_number,
                                        Street_address:
                                          fild_data.Street_address,
                                        Country: fild_data.Country,

                                        typePayment: fild_data.typePayment,
                                        mpesa_type: fild_data.mpesa_type,
                                        Send_Money: fild_data.Send_Money,
                                        paybill_number: e.target.value,
                                        account_number:
                                          fild_data.account_number,
                                        till_number: fild_data.till_number,
                                        tax_identification_number: fild_data.tax_identification_number
                                      });
                                    }}
                                    name="paybill_number"
                                    aria-describedby="basic-addon3"
                                  />
                                </InputGroup>
                              </div>
                              <div
                                style={{ margin: '10px 0px' }}
                                className="input-form-wrapper dot"
                              >
                                <InputGroup className="doc-input-wrapper">
                                  <Form.Control
                                    className={'doc-form-input test valid'}
                                    type="text"
                                    placeholder="Input account number here."
                                    value={fild_data?.account_number}
                                    onChange={(e: any) => {
                                      setFild_data({
                                        First_name: fild_data.First_name,
                                        Last_name: fild_data.Last_name,
                                        Type: fild_data.Type,
                                        Select_fild: fild_data.Select_fild,
                                        Email: fild_data.Email,
                                        Phone_number: fild_data.Phone_number,
                                        Street_address:
                                          fild_data.Street_address,
                                        Country: fild_data.Country,

                                        typePayment: fild_data.typePayment,
                                        mpesa_type: fild_data.mpesa_type,
                                        Send_Money: fild_data.Send_Money,
                                        paybill_number:
                                          fild_data.paybill_number,
                                        account_number: e.target.value,
                                        till_number: fild_data.till_number,
                                        tax_identification_number: fild_data.tax_identification_number
                                      });
                                    }}
                                    name="account_number"
                                    aria-describedby="basic-addon3"
                                  />
                                </InputGroup>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div style={{ padding: '10px 0px 0px 0px' }}>
                        <div
                          className={`mpesa-container__option-wrapper mpaisa ${fild_data.mpesa_type === 'Till_number'
                              ? 'border-green'
                              : ''
                            }`}
                          onClick={() => {
                            setFild_data({
                              First_name: fild_data.First_name,
                              Last_name: fild_data.Last_name,
                              Type: fild_data.Type,
                              Select_fild: fild_data.Select_fild,
                              Email: fild_data.Email,
                              Phone_number: fild_data.Phone_number,
                              Street_address: fild_data.Street_address,
                              Country: fild_data.Country,

                              typePayment: fild_data.typePayment,
                              mpesa_type: 'Till_number',
                              Send_Money: fild_data.Send_Money,
                              paybill_number: fild_data.paybill_number,
                              account_number: fild_data.account_number,
                              till_number: fild_data.till_number,
                              tax_identification_number: fild_data.tax_identification_number
                            });
                          }}
                        >
                          <div className="accordion_p_data_contean">
                            Till number
                          </div>
                          {fild_data.mpesa_type === 'Till_number' ? (
                            <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                          ) : (
                            <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                          )}
                        </div>
                        <div>
                          {fild_data.mpesa_type === 'Till_number' && (
                            <>
                              <div
                                style={{ margin: '10px 0px' }}
                                className="input-form-wrapper dot"
                              >
                                <InputGroup className="doc-input-wrapper">
                                  <Form.Control
                                    className={'doc-form-input test valid'}
                                    placeholder="Input till number here."
                                    value={fild_data?.till_number}
                                    onChange={(e: any) => {
                                      setFild_data({
                                        First_name: fild_data.First_name,
                                        Last_name: fild_data.Last_name,
                                        Type: fild_data.Type,
                                        Select_fild: fild_data.Select_fild,
                                        Email: fild_data.Email,
                                        Phone_number: fild_data.Phone_number,
                                        Street_address:
                                          fild_data.Street_address,
                                        Country: fild_data.Country,

                                        typePayment: fild_data.typePayment,
                                        mpesa_type: fild_data.mpesa_type,
                                        Send_Money: fild_data.Send_Money,
                                        paybill_number: fild_data.paybill_number,
                                        account_number: fild_data.account_number,
                                        till_number: e.target.value,
                                        tax_identification_number: fild_data.tax_identification_number
                                      })
                                    }}
                                    name='till_number'
                                    aria-describedby="basic-addon3"
                                  />
                                </InputGroup>
                              </div>

                            </>)}
                        </div>

                      </div>
                    </> : <>
                      <div className='input-form-wrapper dot'>
                        <label>Bank name</label>
                        <Form.Select value={bank_data.bank_name} onChange={(e: any) => {
                          setBank_data({
                            bank_name: e.target.value,
                            branch_name: bank_data.branch_name,
                            account_name: bank_data.account_name,
                            account_number: bank_data.account_number
                          })
                        }} name="bank_name" as="select"
                          className={'doc-form-input test valid'
                          } >
                          <option value="">--Select recipient bank--</option>
                          {Array.isArray(bankList)
                            ? bankList?.map((element: any) => {
                              return <option key={element.id} value={element.bank_name}>{element.bank_name}</option>
                            })
                            : "data not found"}
                        </Form.Select>

                      </div>
                      <div className="input-form-wrapper mb-4">
                        <label>Account name</label>
                        <Form.Control
                          value={bank_data.account_name}
                          onChange={(e: any) => {
                            setBank_data({
                              bank_name: bank_data.bank_name,
                              branch_name: bank_data.branch_name,
                              account_name: e.target.value,
                              account_number: bank_data.account_number
                            })
                          }}
                          name="account_name"
                          type="text"
                          placeholder="Account name"
                          className={
                            'doc-form-input test valid'
                          }
                        />

                      </div>
                      <div className="input-form-wrapper m-10 dot">
                        <label>Account number</label>
                        <Form.Control
                          value={bank_data.account_number}
                          onChange={(e: any) => {
                            setBank_data({
                              bank_name: bank_data.bank_name,
                              branch_name: bank_data.branch_name,
                              account_name: bank_data.account_name,
                              account_number: e.target.value
                            })
                          }}
                          name="account_number"
                          type="text"
                          placeholder="Account number"
                          className={
                            'doc-form-input test valid'
                          }
                        />

                      </div></>}
                </div>
              ) : (
                ''
              )}
            </ThemeProvider>
          </div>
          <div className="main_buuton_line_logg">
            <Button type="submit" onClick={() => { haredData() }}
              className='mt-3 complete-profile__btn2 text-capitalize'
              disabled={data_submit == true ? true : false}
              style={{ background: '#025041 !important', margin: "20px 0px" }}>
              {data_submit == true ? <span className="spinner-border spinner-border-sm mr-1"></span> : ""}
              {!!datadisplay ? "Update payee" : "Create payee"}
            </Button>
          </div>
        </div>
      </div >

    </>
  )
}
