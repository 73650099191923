import { Button } from "react-bootstrap";
import styles from '../ui.module.scss';

export const ButtonCom = ({ children,onClickset,active }: any) => {

    return (
        <Button className={active==true?styles.buttonstylesrecringactive:styles.buttonstylesrecring} onClick={onClickset}>
            {children}
        </Button>
    )
} 