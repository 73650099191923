import { Autocomplete, TextField } from "@mui/material";
import { Form } from "react-bootstrap";

export const SelectBox = ({ value, onChangeset, list, label,disabled,menuID, currency, style,mainDevstyle,menuName, name }: any) => {

    return (
        <>
            <div style={mainDevstyle} className="input-form-wrapper dot">
                <label>{label}</label>
                <Form.Select
                disabled={disabled}
                    style={{ width: "100%",...style }}
                    name={name}
                    value={value}
                    onChange={onChangeset}
                >
                    <option selected value="">
                        Select {label}
                    </option>
                    {list?.map(
                        (arrayElement: any, index: number) => {
                            return (
                                <option
                                    value={currency == "currency" ? arrayElement.currency : arrayElement.id}
                                    key={index + 1}
                                >
                                    {currency == "currency" ? arrayElement.currency : arrayElement.name}
                                </option>
                            );
                        }
                    )}
                </Form.Select>
            </div>
        </>
    )
}

export const AutocompleteBox = ({ list, value, onChangeset, label, name,disabled }: any) => {

    return (
        <div className="">
            <label className="Category_label">{label}</label>
            <Autocomplete
            disabled={disabled}
                multiple
                sx={{
                    "& .MuiInputBase-root": {
                        display: "flex",
                        alignItems: "center",
                        background: "#FFFFFF",
                        border: "1px solid #DDDDDD",
                        outline: "none",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        color: "#111111",
                        borderRadius: "8px",
                        padding: "7px 9px",
                    },
                }}
                limitTags={2}
                id="multiple-limit-tags"
                options={list}

                value={value}
                getOptionLabel={(option: any) => option.invoice_no}
                onChange={(event: any, newValue: any) => {
                    // console.log(event.targ, listid?.id, "listidlistid");
                    onChangeset(newValue)
                    // var NewArray = []
                    // for (let index = 0; index < listid.length; index++) {
                    //     const element = listid[index];
                    //     NewArray.push(element.id)
                    // }
                    console.log(newValue, "newValue");

                }}
                renderInput={(params) => <TextField  {...params} />}
            // sx={{ width: '500px' }}
            />
        </div>
    )
}