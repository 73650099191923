import { Box, Typography } from '@mui/material';
import styles from './ui.module.scss';
import { Form } from 'react-bootstrap';

export const EmailTep = ({ onChangesubjectset, subjectvelue,onChangeBodyset,body_text }: any) => {

    return (
        <>
            <Box className={styles.Box_classinput}>
                <Box className={styles.Box_conteansubject}>
                    <Typography className={styles.text_subject}>
                        Subject
                    </Typography>
                    <Form.Control
                        className={styles.inputSubject}
                        name="document_id"
                        value={subjectvelue}
                        onChange={(e: any) => {
                            onChangesubjectset(e.target.value)
                        }}
                    />
                </Box>
                <Box className={styles.BoxClasstextarea}>
                    <textarea className={styles.textarea} name="w3review"
                        rows={7}
                        onChange={(e: any) => {
                            onChangeBodyset(e.target.value)
                        }}
                        value={body_text}
                        placeholder=""
                    // cols={58}
                    ></textarea>
                </Box>
            </Box>
        </>
    )
}