import moment from "moment";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap"


export const InvoiceTransactionDetails = ({ data, showModal, onCloseset }: any) => {
    const [option, setOption] = React.useState<any>([])
    console.log(option, data, "datadatadata545");

    useEffect(() => {
        setOption(data);
    }, [data])
    return (
        <>
            <Modal
                show={showModal}
                onHide={onCloseset}
                backdrop="static"
                keyboard={false}
                aria-labelledby='ModalHeader'
                className='topup-modal trans-modal'
                id='afaFafffffFFff'
                centered
            >

                {/* <Modal.Body> */}
                <div style={{ margin: "0px 0px", width: "auto" }} className='trans-detail-container'>
                    <div className='trans-detail-container__heading' style={{ textAlign: "left" }}>
                    Invoice Details
                    </div>
                    <div className='trans-detail-containerX' >
                        {option?.map((item: any, index: any) => {
                            return (
                                <div className='trans-detail-container__table' >
                                    {
                                        (!!item.invoice_no) ?
                                            <div className='dflex b-bot'>
                                                <div className='left'>Invoice Number
                                                </div>
                                                <div className='right'>{item.invoice_no}</div>
                                            </div> : ""
                                    }
                                    {
                                        (!!item.created_at) ?
                                            <div className='dflex b-bot'>
                                                <div className='left'>Invoice Date
                                                </div>
                                                <div className='right'>{moment.tz(item.created_at, "UTC").tz(moment.tz.guess()).format('D MMMM YYYY, h:mm A')}</div>
                                            </div> : ""
                                    }
                                    {
                                        (!!item.due_date) ?
                                            <div className='dflex b-bot'>
                                                <div className='left'>Invoice Due Date

                                                </div>
                                                <div className='right'>{moment.tz(item.due_date, "UTC").tz(moment.tz.guess()).format('D MMMM YYYY, h:mm A')}</div>
                                            </div> : ""
                                    }
                                    {
                                        (!!item.invoice_name) ?
                                            <div className='dflex b-bot'>
                                                <div className='left'>Customer
                                                </div>
                                                <div className='right'>{item.invoice_name}</div>
                                            </div> : ""
                                    }
                                    {
                                        (!!item.total) ?
                                            <div className='dflex b-bot'>
                                                <div className='left'>Amount
                                                </div>
                                                <div className='right'>{item.currency}{' '}{item.total}</div>
                                            </div> : ""
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className='trans-detail-container__btn-row' style={{ marginTop: "40px" }}>
                    <Button className='trans-detail-container__btn-row__green'
                        style={{
                            backgroundColor: "white",
                            border: "1px solid black"
                        }}
                        onClick={() => {
                            onCloseset()
                        }}>
                        Close
                    </Button>
                    </div>
                </div>
            </Modal>

        </>
    )
}