import { Button } from 'react-bootstrap';
import Customers_Tebal from './components/Customers_Tebal/Customers_Tebal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Customers_details from './components/Costomers_details/index';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getUser } from '../../../../_helpers';
import styles from './Customers.module.scss';

const Customers = () => {
    const [dataStore, setDataStore] = useState<any>()
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState('')
    const user = getUser()?.data ?? null;

    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });
    const navigate = useNavigate();
    useEffect(() => {
        const id: any = searchParams.get('id');
        setId(id)
    }, [searchParams]);
    return (
        <>
            <div className="balance">
               {!!id ?"":
                <div className={`${styles.customer_btn_wrapper} balance__header`}>
                    <div className="balance__header__name">Customers</div>
                    {user.role_name == 'Associate'||user.role_name=="Auditor" ? "" :
                    <div>
                        <>
                            <Button
                                className={`balance__save-btn ${styles.custom_customer_btn_width} custom_mobile_btn`}
                                onClick={() => {
                                    navigate("/create-customer")
                                }}
                            >
                                Create new
                            </Button>
                        </>
                    </div>}
                </div>}
                <ThemeProvider theme={theme}>
                    {!!id ?
                        <Customers_details id={id} data={dataStore} />
                        : <Customers_Tebal setId={setId} />
                    }
                </ThemeProvider>
            </div>
        </>
    )
}
export default Customers;
