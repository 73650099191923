import { Box, Grid } from "@mui/material";
import styles from '../ui.module.scss';
import { GoBackButton } from "../Button";
import { useNavigate } from "react-router-dom";
export const CreateLayout = ({ children, Path }: any) => {
    const navigate = useNavigate();

    return (
        <>
            <Grid container>
                <Grid item md={3}>
                    <Box sx={{ padding: "12px" }}>
                        <GoBackButton 
                        onClick={() => {
                            navigate(Path)
                        }} 
                        // onClick={()=>{
                        //     navigate("/invoice?id=" + moniteId)
                        //  }} 
                         />
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Box className={styles.Box_layoutclass}>
                        {children}
                    </Box>
                </Grid>
                <Grid item md={3}>

                </Grid>
            </Grid>
        </>
    )
}