import { Typography } from "@mui/material"
import styles from './ui.module.scss';
export const MainHeding = ({ Text, style }: any) => {

    return (
        <Typography className={styles.typo_MAIN} style={style}>{Text}</Typography>
    )
}

export const NormalText=({children,style}:any)=>{

    return(
        <Typography sx={style} className={styles.Typography_Normal}>{children}</Typography>
    )
}